import { notification } from "antd";
import axios from "axios";

export const getDayName = (date = new Date()) => {
  return new Date(date).toLocaleDateString("en-US", { weekday: "long" });
};

export const getFileDimensions = (file, loadCB) => {
  let img = new Image();
  let objectUrl = window.URL.createObjectURL(file);
  img.src = objectUrl;
  img.onload = function () {
	  loadCB(this);
  };
  
};

export const getlongLat =async (address) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json`,
    {
      params: {
        address: address,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    }
  );
  return response.data.results[0].geometry.location;
}

export const getTimeZone=async (lat, lng)=> {
  try{
    const timestamp = Math.floor(Date.now() / 1000);
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json`,
      {
        params: {
          location: `${lat},${lng}`,
          timestamp: timestamp,
          key: process.env.REACT_APP_GOOGLE_API_KEY,
        },
      }
    );
    notification.success({
      message: "Timezone",
      description: response.data.timeZoneId,
    });
    return response.data.timeZoneId;
  }
  catch{
    notification.error({
      message: "Timezone",
      description: "Failed to get timezone",
    })
    return "UTC";
  }

}

