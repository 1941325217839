import React from "react";
import { Select } from "antd";

const { Option } = Select;

const Step1Panel = ({
  serviceTitanBusinessUnits,
  serviceTitanJobs,
  serviceTitanJobTags,
  tagIds,
  setTagIds,
  selectedBu,
  setSelectedBu,
  selectedJobType,
  setSelectedJobType,
  CampaignList,
  setSelectedCampaign,
  filterOption,
  selectedCampaign,
}) => {
  console.log("campaign list")
  console.log(CampaignList)
  console.log(tagIds)
  
  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <label>Business Unit (BU)</label>
        <Select
          placeholder="Select Business Unit"
          value={selectedBu?.id}
          onChange={(value) => {
            const selectedBu = serviceTitanBusinessUnits.find(
              (e) => e.id === value
            );
            setSelectedBu(selectedBu);
          }}
          style={{ width: "100%" }}
        >
          {serviceTitanBusinessUnits.map((bu) => (
            <Option key={bu.id} value={bu.id}>
              {bu.name}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: "16px" }}>
        <label>Job Type</label>
        <Select
          placeholder="Select Job Type"
          value={selectedJobType?.id}
          onChange={(value) => {
            const selectedJob = serviceTitanJobs.find((e) => e.id === value);
            setSelectedJobType(selectedJob);
          }}
          style={{ width: "100%" }}
        >
          {serviceTitanJobs.map((job) => (
            <Option key={job.id} value={job.id}>
              {job.name}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <label>Select Campaign</label>
        <Select
          style={{ width: "100%" }}
          allowClear
          placeholder="Select Campaign"
          value={selectedCampaign?.name}
          onChange={(value) => {
            const campaign = CampaignList.find((e) => e.value === value);

            console.log("campaign",campaign)
            setSelectedCampaign({
              id: campaign.value,
              name: campaign.label,
            });
          }}
          filterOption={filterOption}
          showSearch
        >
          {CampaignList.map((campaign,index) => (
            <Option key={index} value={campaign.value}>
              {campaign.label}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <label>Select Job tags</label>
        <Select
                    mode="multiple"
                    placeholder="Select job tags"
                    style={{ width: "100%", padding: "5px" }}
                    options={
                      serviceTitanJobTags?.data
                        ? serviceTitanJobTags.data.map((item) => ({
                            label: item.name,
                            value: item.id,
                            desc: item.name,
                          }))
                        : []
                    }
                    defaultValue={
                      serviceTitanJobTags?.data
                        ? serviceTitanJobTags.data
                            .filter((item) => tagIds?.includes(item.id))
                            .map((data) => {
                              return data.id;
                            }) ?? []
                        : []
                    }
                    value={
                      serviceTitanJobTags?.data
                        ? serviceTitanJobTags.data
                            .filter((item) => tagIds?.includes(item.id))
                            .map((data) => {
                              return data.id;
                            }) ?? []
                        : []
                    }
                    onChange={(value) => {
                      setTagIds([...value]);
                    }}
                    size="large"
                  ></Select>
      </div>
    </div>
  );
};

export default Step1Panel;
