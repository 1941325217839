import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PublicRouter, ClubRouter } from "./routes";
import { Layout, Spin, theme } from "antd";
import { DashboardHeader } from "./components/General/Header";
import SideNavbar from "./components/General/Navbar/SideNav";
import { verifyToken } from "./redux/authSlice";

const { Content } = Layout;

//App
const App = () => {
	const dispatch = useDispatch();
	const { isAuthenticated, token, authLoading } = useSelector(
		(state) => state?.auth
	);
	const [collapsed, setCollapsed] = useState(false);
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	useEffect(() => {
		if (token) {
			dispatch(verifyToken({ token }));
		}
	}, [dispatch, token]);

	return (
		<>
			{authLoading ? <Spin spinning={authLoading} fullscreen /> : null}
			{isAuthenticated ? (
				<Layout style={{ height: "100%" }}>
					<SideNavbar collapsed={collapsed} />
					<Layout>
						<DashboardHeader
							collapsed={collapsed}
							setCollapsed={setCollapsed}
							colorBgContainer={colorBgContainer}
						/>
						<Content
							style={{
								margin: "24px 16px",
								padding: 24,
								minHeight: 280,
								background: colorBgContainer,
								overflowY: "auto",
								overflowX: "auto",
							}}
						>
							<ClubRouter />
						</Content>
					</Layout>
				</Layout>
			) : (
				<PublicRouter />
			)}
		</>
	);
};
export default App;
