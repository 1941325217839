import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Spin, Table, Typography } from "antd";
// import { DeleteFilled } from "@ant-design/icons";

const { Text } = Typography;

const JobsOfferingListing = () => {
	const { jobs = [], loading } = useSelector((state) => state.jobs);

	const [sortedInfo, setSortedInfo] = useState({});
	const handleChange = (pagination, filters, sorter) => {
		setSortedInfo(sorter);
	};

	const columns = [
		{
			title: "Trade",
			dataIndex: "trade",
			key: "trade",
			sorter: (a, b) => a.trade.tradeName.length - b.trade.tradeName.length,
			sortOrder: sortedInfo.columnKey === "trade" ? sortedInfo.order : null,
			ellipsis: true,
			width: 200,
			render: (item) => <Text>{item?.tradeName}</Text>,
		},
		{
			title: "Job",
			dataIndex: "jobItem",
			key: "jobItem",
			sorter: (a, b) => a.jobItem.length - b.jobItem.length,
			sortOrder: sortedInfo.columnKey === "jobItem" ? sortedInfo.order : null,
			ellipsis: true,
			width: 200,
		},
		{
			title: "Service Type",
			dataIndex: "serviceType",
			key: "serviceType",
			sorter: (a, b) => a.serviceType.length - b.serviceType.length,
			sortOrder:
				sortedInfo.columnKey === "serviceType" ? sortedInfo.order : null,
			ellipsis: true,
			width: 150,
		},
	];
	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Table
				columns={columns}
				dataSource={jobs}
				onChange={handleChange}
				pagination={false}
			/>
		</>
	);
};

export default JobsOfferingListing;
