import {
	GoogleOutlined,
	YahooOutlined,
	FacebookOutlined,
	ArrowRightOutlined,
	ForwardOutlined,
} from "@ant-design/icons";
import { BiLogoBing, BiLogoYelp } from "react-icons/bi";

export const Campaigns = [
	{
		id: 1,
		trafficSource: "Organic",
		medium: "organic",
		colorCode: "#006400",
		sources: [
			{
				id: 1,
				source: "google",
				logo: <GoogleOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
			{
				id: 2,
				source: "bing",
				logo: <BiLogoBing />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
			{
				id: 3,
				source: "yahoo",
				logo: <YahooOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
			{
				id: 4,
				source: "gbp",
				logo: <ForwardOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
		],
	},
	{
		id: 2,
		trafficSource: "Paid Traffic",
		medium: "cpc",
		colorCode: "#FF0000",
		sources: [
			{
				id: 1,
				source: "google",
				logo: <GoogleOutlined />,
			},
			{
				id: 2,
				source: "bing",
				logo: <BiLogoBing />,
			},
		],
	},
	{
		id: 3,
		trafficSource: "Referral Traffic",
		medium: "referral",
		colorCode: "#6C91D6",
		sources: [
			{
				id: 1,
				source: "yelp.com",
				logo: <BiLogoYelp />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
			{
				id: 2,
				source: "m.yelp.com",
				logo: <BiLogoYelp />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
			{
				id: 3,
				source: "l.facebook.com",
				logo: <FacebookOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
			{
				id: 4,
				source: "m.facebook.com",
				logo: <FacebookOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
		],
	},
	{
		id: 3,
		trafficSource: "Direct Traffic",
		medium: "none",
		colorCode: "#FF5733",
		sources: [
			{
				id: 1,
				source: "direct",
				logo: <ArrowRightOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
		],
	},
	{
		id: 4,
		trafficSource: "Other",
		colorCode: "#a8a7a8",
		sources: [
			{
				id: 1,
				source: "other",
				logo: <ArrowRightOutlined />,
				st_campaign_id: "",
				st_campaign_name: "",
			},
		],
	},
];