import { useState } from "react";
import { Button, Flex, Input, Typography, List, Row, Col, Spin } from "antd";
import { InsertRowLeftOutlined, DeleteFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "../../redux/organizationSlice";

const { Title } = Typography;

const NotificationAccounts = () => {
	const dispatch = useDispatch();
	const { organizationData, loading } = useSelector(
		(state) => state.organization
	);
	const [account, setAccount] = useState("");

	const addNotificationAccount = () => {
		if (account) {
			dispatch(
				updateOrganization({
					id: organizationData._id,
					payload: {
						notificationAccounts: [
							...organizationData?.notificationAccounts,
							account,
						],
					},
				})
			);
			setAccount("");
		}
	};

	const deleteAccount = (account) => {
		const accountsList = organizationData?.notificationAccounts?.filter(
			(e) => e !== account
		);
		dispatch(
			updateOrganization({
				id: organizationData._id,
				payload: {
					notificationAccounts: [...accountsList],
				},
			})
		);
	};

	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<InsertRowLeftOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Add Notification Accounts
				</Title>
			</Flex>
			<Row>
				<Col span={6}>
					<Flex vertical={true} gap={20}>
						<Input
							placeholder="Add New Account"
							value={account}
							type="email"
							onChange={(e) => setAccount(e.target.value)}
							size="large"
						/>
						<Button
							type="primary"
							onClick={addNotificationAccount}
							disabled={account.trim() === ""}
							size="large"
							style={{ width: "fit-content" }}
						>
							Add
						</Button>
					</Flex>
				</Col>
			</Row>

			<Flex vertical gap={20} style={{ margin: "20px 0px" }}>
				<Title level={3}>Notification Receiving Accounts</Title>
				<List
					size="large"
					bordered
					dataSource={organizationData?.notificationAccounts}
					renderItem={(item) => (
						<List.Item key={item}>
							<List.Item.Meta title={item} />
							<DeleteFilled
								style={{ color: "#ff4d4f" }}
								height="1em"
								onClick={() => deleteAccount(item)}
							/>
						</List.Item>
					)}
				/>
			</Flex>
		</>
	);
};

export default NotificationAccounts;
