import React, { useEffect } from "react";
import { Col, Flex, Form, Image, Row, Spin, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { useParams } from "react-router-dom";
import { signUp, verifySignUpToken } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;

const Signup = () => {
	const dispatch = useDispatch();
	const { user, loading } = useSelector((state) => state?.auth);

	const navigate = useNavigate();
	const { token,type } = useParams();

	useEffect(() => {
		if (token) {
			dispatch(verifySignUpToken({ token,type }));
		} else {
			navigate("/");
		}
	}, [dispatch, navigate, token]);

	const handleSubmit = (values) => {
		const { password } = values;
		dispatch(signUp({ password, token }));
	};

	return (
		<Row style={{ height: "100%" }}>
			<Col span={12} style={{ background: "#fed123", height: "100%" }}>
				<Flex justify="center" align="center" style={{ height: "100%" }}>
					<Image
						src="https://autobot.ai/img/autologo.svg"
						height={100}
						preview={false}
					/>
				</Flex>
			</Col>
			<Col
				span={12}
				style={{
					backgroundImage: `url(https://autobot.ai/img/Whats-the-buzz-bg.png)`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundColor: "#fafcff",
				}}
			>
				{loading ? (
					<Spin spinning={loading} fullscreen />
				) : (
					<Flex
						justify="center"
						align="center"
						vertical={true}
						style={{ height: "100%" }}
						gap={30}
					>
						<Image
							src="https://autobot.ai/img/Logo_bot_icon_new.svg"
							height={80}
							preview={false}
						/>
						<Form
							name="basic"
							style={{
								maxWidth: 300,
								width: 300,
							}}
							initialValues={{
								remember: true,
							}}
							layout="vertical"
							onFinish={(values) => handleSubmit(values)}
							autoComplete="on"
						>
							<Form.Item
								wrapperCol={{
									offset: 4,
									span: 24,
								}}
							>
								<Text strong style={{ fontSize: 16 }}>
									{user?.email}
								</Text>
							</Form.Item>

							<Form.Item
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: "Please input your password!",
									},
									{
										min: 8,
										message: "Please enter atleast 8 characters",
									},
								]}
								hasFeedback
							>
								<Input.Password />
							</Form.Item>

							<Form.Item
								name="confirm"
								label="Confirm Password"
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "Please confirm your password!",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													"The new password that you entered do not match!"
												)
											);
										},
									}),
								]}
							>
								<Input.Password />
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Flex>
				)}
			</Col>
		</Row>
	);
};

export default Signup;
