import { Select, Flex, Typography } from "antd";
const { Text } = Typography;
export const SingleSelect = ({ title, ...rest }) => {
	return (
		<Flex vertical gap={10}>
			<Text type="secondary" strong underline>
				{title}
			</Text>
			<Select {...rest} />
		</Flex>
	);
};
