// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root {
	height: 100%;
	width: 100%;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0px !important;
}

.ant-notification-notice-error {
	background: #fff2f0;
	border: 1px solid #ffccc7;
}

.ant-notification-notice-success {
	background: #f6ffed;
	border: 1px solid #b7eb8f;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

.address-autofill {
	width: -webkit-fill-available;
	height: 40px;
	padding: 14px 14px 12px 14px;
	font-family: Verdana, sans-serif;
	border-color: #d9d9d9;
	min-width: 0;
	background-color: #ffffff;
	background-image: none;
	border-width: 1px;
	border-style: solid;
	border-color: #d9d9d9;
	border-radius: 6px;
	transition: all 0.2s;
	color: rgba(0, 0, 0, 0.88);
	font-size: 14px;
	line-height: 1.5714285714285714;
}

.address-autofill:focus-visible {
	border-color: blue !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;CAGC,YAAY;CACZ,WAAW;CACX,SAAS;CACT;;YAEW;CACX,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC;WACU;AACX;;AAEA;;;;;CAKC,sBAAsB;AACvB;;AAEA;CACC,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;CACC,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;CACC,wBAAwB;AACzB;;AAEA;CACC,6BAA6B;CAC7B,YAAY;CACZ,4BAA4B;CAC5B,gCAAgC;CAChC,qBAAqB;CACrB,YAAY;CACZ,yBAAyB;CACzB,sBAAsB;CACtB,iBAAiB;CACjB,mBAAmB;CACnB,qBAAqB;CACrB,kBAAkB;CAClB,oBAAoB;CACpB,0BAA0B;CAC1B,eAAe;CACf,+BAA+B;AAChC;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":["html,\nbody,\n#root {\n\theight: 100%;\n\twidth: 100%;\n\tmargin: 0;\n\tfont-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n\t\t\"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n\t\tsans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n\t\tmonospace;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n\tmargin: 0px !important;\n}\n\n.ant-notification-notice-error {\n\tbackground: #fff2f0;\n\tborder: 1px solid #ffccc7;\n}\n\n.ant-notification-notice-success {\n\tbackground: #f6ffed;\n\tborder: 1px solid #b7eb8f;\n}\n\niframe#webpack-dev-server-client-overlay {\n\tdisplay: none !important;\n}\n\n.address-autofill {\n\twidth: -webkit-fill-available;\n\theight: 40px;\n\tpadding: 14px 14px 12px 14px;\n\tfont-family: Verdana, sans-serif;\n\tborder-color: #d9d9d9;\n\tmin-width: 0;\n\tbackground-color: #ffffff;\n\tbackground-image: none;\n\tborder-width: 1px;\n\tborder-style: solid;\n\tborder-color: #d9d9d9;\n\tborder-radius: 6px;\n\ttransition: all 0.2s;\n\tcolor: rgba(0, 0, 0, 0.88);\n\tfont-size: 14px;\n\tline-height: 1.5714285714285714;\n}\n\n.address-autofill:focus-visible {\n\tborder-color: blue !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
