import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PublicRoutes, AuthenticatedRoutes } from "../utils/constants";
import {useEffect } from "react";
import { fetchServices, fetchTrades } from "../redux/tradeSlice";
import { fetchJobs } from "../redux/jobsSlice";
import {
	fetchOrganzation,
	fetchSTJobTags,
} from "../redux/organizationSlice";
import { fetchAllOrganzations } from "../redux/adminSlice";

const ClubRouter = () => {
	const dispatch = useDispatch();
	const { isAuthenticated, authLoading, isAdmin, currentOrganizationId } =
		useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(fetchTrades());
		dispatch(fetchServices());
		dispatch(fetchJobs());
	}, [dispatch]);

	useEffect(() => {
		if (isAuthenticated && currentOrganizationId && !authLoading) {
			dispatch(fetchOrganzation(currentOrganizationId));
			dispatch(fetchSTJobTags(currentOrganizationId));
		}
	}, [dispatch, authLoading, isAuthenticated, currentOrganizationId]);

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(fetchAllOrganzations());
		}
	}, [dispatch, isAuthenticated, isAdmin]);

	return (
		<Routes>
			{isAuthenticated
				? AuthenticatedRoutes.map((item) => {
						if (
							!item.isAdminRestricted ||
							(item.isAdminRestricted && isAdmin)
						) {
							return (
								<Route
									key={item.name}
									path={item.routePath}
									element={item.component}
								/>
							);
						}
						return null;
				  })
				: null}

			{isAdmin ? (
				<Route path="*" element={<Navigate to="/adminSettings" />} />
			) : (
				<Route path="*" element={<Navigate to="/orgSettings" />} />
			)}
		</Routes>
	);
};

const PublicRouter = () => {
	const { isAuthenticated } = useSelector((state) => state.auth);
	return (
		<Routes>
			{!isAuthenticated
				? PublicRoutes.map((item) => {
						return (
							<Route
								key={item.name}
								path={item.routePath}
								element={item.component}
							/>
						);
				  })
				: null}
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};

export { ClubRouter, PublicRouter };
