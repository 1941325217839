import axios from "axios";
import moment from "moment";
import {
  Button,
  Card,
  Typography,
  notification,
  Table,
  Modal,
  message,
  Collapse,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSTBusinessUnits,
  fetchSTCampaigns,
  organization_custom_booking_url,
} from "../../redux/organizationSlice";

import Step1Panel from "./panels/Step1Panel";
import Step2Panel from "./panels/Step2Panel";

const { Title } = Typography;
const { Panel } = Collapse;

const initialDispatchFeeDetails = {
  dispatch_fee: 0,
  member_dispatch_fee: 0,
  use_default_dispatch: true,
  member_is_active: false,
  dispatch_header: "This visit requires a diagnostic fee of {{dispatch_fee}}",
  dispatch_body: "I agree to a diagnostic investment of {{dispatch_fee}}, which is credited back if I approve the repairs on the day of the visit.",
  dispatch_text: "I agree",
};

export default function GenerateCustomURL() {
  const {
    organizationData,
    serviceTitanBusinessUnits,
    serviceTitanJobs,
    serviceTitanJobTags,
    serviceTitanCampaigns
  } = useSelector((state) => state.organization);
  const [campaignName, setCampaignName] = useState("");
  const [generatedURLs, setGeneratedURLs] = useState([]);
  const [selectedBu, setSelectedBu] = useState(null);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedWebAddress, setSelectedWebAddress] = useState(
    ""
  );
  const [urlcode, seturlcode] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [isdispatchFee, setIsDispatchFee] = useState(false);
  const [customerType, setCustomerType] = useState("Existing Customer");
  const [dispatchDetails, setDispatchDetails] = useState(
    initialDispatchFeeDetails
  ); // State for DispatchText
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [campaignData, setCampaignData] = useState(
    organizationData?.campaigns_mapping || []
  );
  const [tagIds, setTagIds] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => (
        <span style={{ color: "#6b7280" }}>{text}</span>
      ),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (text) => (
        <span style={{ fontWeight: "600", color: "#374151" }}>{text}</span>
      ),
    },
    {
      title: "Generated URL",
      dataIndex: "customUrl",
      key: "customUrl",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#3b82f6",
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 600,
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button
          style={{
            backgroundColor: "#10b981",
            color: "#fff",
            borderRadius: "5px",
            border: "none",
          }}
          onClick={() => {
            console.log("tag Ids")
            console.log(record.tagIds)
            seturlcode(record.urlCode);
            setCampaignName(record.campaignName);
            setCustomerType(record.customerType);
            setSelectedCampaign(record.campaignData);
            setSelectedWebAddress(record.customUrl.split("?")[0]);
            setSelectedBu(record.buId);
            setSelectedJobType(record.jobType);
            setTagIds(record.tagIds);
            setAdditionalInfo(record.additionalInfo);
            setIsDispatchFee(record.dispatchFee);
            setDispatchDetails(record.dispatchFeeDetails);
            setUpdateModal(true);
            setIsModalVisible(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setCampaignData(organizationData?.campaigns_mapping || []);
  }, [organizationData?.campaigns_mapping]);

  useEffect(() => {
    dispatch(fetchSTCampaigns(organizationData?._id));
    dispatch(fetchSTBusinessUnits(organizationData?._id));
    async function fetchCustomURL() {
      const response = await axios.get(
        `http://localhost:5000/org/fetch/customurl/${organizationData?._id}`
      );
      setGeneratedURLs(response?.data || []);
    }
    void fetchCustomURL();
  }, [organizationData]);

  const generateRandom16DigitNumber = () => {
    return Math.floor(Math.random() * 1e16)
      .toString()
      .padStart(16, "0");
  };

  const generateCustomURL = () => {
    if (!campaignName || !selectedWebAddress) {
      notification.error({
        message: "Error",
        description: "Please fill out all fields to generate the URL.",
        duration: 3,
      });
      return;
    }

    const customUrlCode = updateModal ? urlcode : generateRandom16DigitNumber();

    const url = new URL(selectedWebAddress);
    url.searchParams.append("schedtag", "true");
    url.searchParams.append("customUrlCode", customUrlCode);
    const newEntry = {
      organizationId: organizationData._id,
      dateCreated: moment().format("ddd, DD YYYY"),
      urlCode: customUrlCode,
      customerType: customerType,
      campaignName: campaignName,
      buId: selectedBu,
      jobType: selectedJobType,
      tagIds: tagIds,
      customUrl: url.toString(),
      additionalInfo: additionalInfo,
      dispatchFee: isdispatchFee,
      dispatchFeeDetails:
        isdispatchFee === false
          ? initialDispatchFeeDetails
          : dispatchDetails,
      campaignData: selectedCampaign,
    };
    try {
      dispatch(
        organization_custom_booking_url({
          id: organizationData._id,
          payload: newEntry,
        })
      );
      if (updateModal) {
        const index = generatedURLs.findIndex(
          (item) => item.urlCode === customUrlCode
        );
        generatedURLs[index] = newEntry;
        setGeneratedURLs([...generatedURLs]);
      } else {
        setGeneratedURLs([...generatedURLs, newEntry]);
      }

      navigator.clipboard
        .writeText(url.toString())
        .then(() => {
          messageApi.success({
            type: "success",
            content: "URL copied to clipboard.",
          });
        })
        .catch(() => {
          messageApi.error({
            type: "error",
            content: "Failed to copy URL to clipboard.",
          });
        });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to generate custom URL.",
        duration: 3,
      });
    } finally {
      setIsModalVisible(false);
    }
  };

  const handleDispatchTextChange = (value, jobItemId) => {
    const key = Object.keys(value)[0];
    if (key === "use_default_dispatch" && value[key] === true) {
      const default_dispatch_body = organizationData?.default_dispatch_body;
      const default_dispatch_header = organizationData?.default_dispatch_header;
      const default_dispatch_text = organizationData?.default_dispatch_text;
      setDispatchDetails((prev) => ({
        ...prev,
        [key]: value[key],
        dispatch_body: default_dispatch_body,
        dispatch_header: default_dispatch_header,
        dispatch_text: default_dispatch_text,
      }));
      return;
    }
    setDispatchDetails((prev) => ({ ...prev, [key]: value[key] }));
  };

  const webAddresses = [
    organizationData?.organization_webAddress,
    ...(organizationData?.organization_domains || []),
  ].filter(Boolean);

  const handleChange = (val) => {
    setCampaignData(val);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

 
	const CampaignsList =
		serviceTitanCampaigns?.map((item) => {
			return {
				value: item?.id,
				label: item?.name,
			};
		}) || [];

  return (
    <>
      {contextHolder}
      <Title
        level={3}
        style={{ marginBottom: "24px", color: "#1f2937", fontWeight: "700" }}
      >
        Quick Booking Links
      </Title>

      <div
        style={{
          padding: "24px",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "16px",
          }}
        >
          <Button
            type="default"
            style={{
              background: "linear-gradient(90deg, #667eea 0%, #764ba2 100%)",
              color: "#fff",
              borderRadius: "5px",
              border: "none",
            }}
            onClick={() => {
              setCampaignName("");
              setSelectedWebAddress('')
              setCustomerType({});
              setSelectedCampaign({});
              setSelectedBu({});
              setSelectedJobType({});
              setTagIds([]);
              setAdditionalInfo(false);
              setIsDispatchFee(false);
              setDispatchDetails(initialDispatchFeeDetails);
              setUpdateModal(false);
              setIsModalVisible(true);
            }}
          >
            Generate New Link
          </Button>
        </div>

        <Card
          bordered={false}
          style={{
            width: "100%",
            boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
        >
          <Table
            dataSource={generatedURLs}
            columns={columns}
            rowKey="urlCode"
            pagination={{
              pageSize: 5,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
            style={{ marginTop: "16px" }}
          />
        </Card>
      </div>

      <Modal
        title={
          updateModal
            ? "Update Custom Booking URL"
            : "Generate Custom Booking URL"
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsModalVisible(false)}
            style={{
              borderRadius: "5px",
              color: "#6b7280",
            }}
          >
            Cancel
          </Button>,
          <Button
            key="generate"
            type="primary"
            onClick={generateCustomURL}
            style={{
              backgroundColor: "#10b981",
              borderRadius: "5px",
              border: "none",
            }}
          >
            {updateModal ? "Update Link" : "Generate Link"}
          </Button>,
        ]}
        width={800}
      >
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="Step 1: Enter Campaign Details" key="1">
            <label
              style={{
                fontWeight: "600",
                color: "#374151",
                display: "block",
                marginBottom: "8px",
              }}
            >
              Enter Campaign Name
            </label>
            <Input
              placeholder="Campaign Name"
              onChange={(e) => setCampaignName(e.target.value)}
              value={campaignName}
              style={{
                width: "100%",
                marginBottom: "16px",
                borderRadius: "5px",
              }}
            />
          </Panel>
          <Panel header="Step 2: Job Booking Details" key="2">
            <Step1Panel
              serviceTitanBusinessUnits={serviceTitanBusinessUnits}
              serviceTitanJobs={serviceTitanJobs}
              selectedCampaign={selectedCampaign}
              selectedJobType={selectedJobType}
              selectedBu={selectedBu}
              CampaignList={CampaignsList}
              serviceTitanJobTags={serviceTitanJobTags}
              tagIds={tagIds}
              setTagIds={setTagIds}
              setSelectedBu={setSelectedBu}
              setSelectedJobType={setSelectedJobType}
              handleChange={handleChange}
              setSelectedCampaign={setSelectedCampaign}
              filterOption={filterOption}
            />
          </Panel>
          <Panel header="Step 3: Select User Flows" key="3">
            <Step2Panel
              customerType={customerType}
              campaignName={campaignName}
              webAddresses={webAddresses}
              selectedWebAddress={selectedWebAddress}
              isdispatchFee={isdispatchFee}
              additionalInfo={additionalInfo}
              dispatchDetails={dispatchDetails}
              setCustomerType={setCustomerType}
              setCampaignName={setCampaignName}
              setSelectedWebAddress={setSelectedWebAddress}
              setAdditionalInfo={setAdditionalInfo}
              setIsDispatchFee={setIsDispatchFee}
              handleDispatchTextChange={handleDispatchTextChange}
            />
          </Panel>
        </Collapse>
      </Modal>
    </>
  );
}
