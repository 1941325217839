import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import authReducer from "./authSlice";
import tradeReducer from "./tradeSlice";
import jobsReducer from "./jobsSlice";
import organizationSlice from "./organizationSlice";
import adminSlice from "./adminSlice";

const reducers = combineReducers({
	auth: authReducer,
	trade: tradeReducer,
	jobs: jobsReducer,
	organization: organizationSlice,
	admin: adminSlice,
});

const persistConfig = {
	key: "root",
	storage,
	version: 1,
	whitelist: ["auth"], //Things to persist
};

const rootReducer = (state, action) => {
	return reducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
