import {
	Button,
	Checkbox,
	Col,
	Divider,
	Flex,
	Form,
	Row,
	Spin,
	Typography,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
	updateOrganization,
	updateServiceTypeOfferings,
	updateTradeOfferings,
} from "../../redux/organizationSlice";
import { TradeIcons } from "../../utils/tradeIcons";
import React from "react";
const { Title, Text } = Typography;

const TradeServiceOfferings = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { organizationData, loading } = useSelector(
		(state) => state?.organization
	);

	const onChangeTrade = (trade, value) => {
		dispatch(updateTradeOfferings({ trade, value }));
	};

	const onChangeServiceType = (trade, serviceType, value) => {
		dispatch(updateServiceTypeOfferings({ trade, serviceType, value }));
	};

	const handleSubmit = () => {
		const payload = {
			navigation_mapping: organizationData?.navigation_mapping,
		};
		dispatch(updateOrganization({ id: organizationData._id, payload }));
	};

	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<SettingOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Trades and Service Offerings
				</Title>
			</Flex>

			<Form
				form={form}
				onFinish={handleSubmit}
				layout="vertical"
				labelCol={{
					span: 6,
				}}
				wrapperCol={{
					span: 20,
				}}
				autoComplete="new-state"
			>
				<Row>
					{organizationData?.navigation_mapping?.map((trade) => {
						return (
							<React.Fragment key={trade?.trade?.id}>
								<Col span={1}>
									<Flex align="center" style={{ marginTop: 25 }}>
										{TradeIcons[trade?.trade?.name].icon()}
									</Flex>
								</Col>
								<Col span={6}>
									<Checkbox
										key={trade?.trade?.id}
										checked={trade?.isActive}
										onChange={(e) =>
											onChangeTrade(trade?.trade?.name, e.target.checked)
										}
										style={{ margin: "20px 0px" }}
									>
										{/* <Flex align="center" gap={50}> */}
										<Text strong style={{ fontSize: 18 }}>
											{trade?.trade?.name}
										</Text>

										{/* </Flex> */}
									</Checkbox>
									<Flex>
										{Object.keys(trade?.offeredServiceTypes)?.map(
											(serviceType) => {
												return (
													<Checkbox
														key={serviceType}
														checked={trade?.offeredServiceTypes[serviceType]}
														onChange={(e) =>
															onChangeServiceType(
																trade?.trade?.name,
																serviceType,
																e.target.checked
															)
														}
														disabled={!trade?.isActive}
													>
														<Text>
															{serviceType.charAt(0).toUpperCase() +
																serviceType.slice(1)}
														</Text>
													</Checkbox>
												);
											}
										)}
									</Flex>
									<Divider />
								</Col>
							</React.Fragment>
						);
					})}
				</Row>

				<Button type="primary" htmlType="submit" size="large">
					Save
				</Button>
			</Form>
		</>
	);
};

export default TradeServiceOfferings;
