import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { jwtDecode } from "jwt-decode";
import { emailType } from "../utils/constants.js";
import axios from "./apiAxios.js";

export const login = createAsyncThunk(
	"auth/login",
	async ({ email, password }, { rejectWithValue }) => {
		try {
			const values = { email, password };
			const { data } = await axios.post(`/auth/user/login`, values);
			localStorage.setItem("token", data);
			const decodedData = jwtDecode(data);
			decodedData["token"] = data;
			return decodedData;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message || "Login Attempt Failed",
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const signUp = createAsyncThunk(
	"auth/signUp",
	async ({ password, token }, { rejectWithValue }) => {
		try {
			const values = { password, token };
			const { data } = await axios.post(`/auth/user/setpassword`, values);
			if (data) {
				notification.success({
					message: "Success",
					description: "Your password has been reset successfully. You will now be redirected to the sign-in page.",
					duration: 2,
				});
				setTimeout(() => {
					window.location = "/login";
					return data;
				}, 2000);
			}
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const verifyToken = createAsyncThunk(
	"auth/token/verify",
	async ({ token }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/auth/verify/user/token`, { token });
			return data;
		} catch (err) {
			localStorage.clear();
			return rejectWithValue(err.response.data.message);
		}
	}
);
export const verifySignUpToken = createAsyncThunk(
	"auth/token/signUpverify",
	async ({ token ,type }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/auth/verify/signUp/token`, { token,type });
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			localStorage.clear();
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const forgotUserPassword = createAsyncThunk("auth/forgetPassword",async ({email},{ rejectWithValue })=>{
	try {
		const { data } = await axios.post(`/auth/user/forgetpassword`,{email,type:emailType.Reset}); 
		notification.success({
			message: "Success",
			description:"Password Reset Email Sent",
			duration: 3,
		});
		return data;
	} catch (err) {
		notification.error({
			message: "Error",
			description: err.response.data.message,
			duration: 3,
		});
		return rejectWithValue(err.response.data.message);
	}
})

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		authLoading: false,
		user: null,
		isAdmin: false,
		isAuthenticated: false,
		currentOrganizationId: null,
		currentOrganizationIds: [],
		token: null,
		error: null,
	},
	reducers: {
		authenticate: (state) => {
			state.isAuthenticated = !state.isAuthenticated;
		},
		setOrganizationId: (state, { payload }) => {
			state.currentOrganizationId = payload;
		},
		handleLogout: (state) => {
			localStorage.clear();
			state.user = null;
			state.isAdmin = false;
			state.isAuthenticated = false;
			state.currentOrganizationId = null;
			state.token = null;
		},
	},
	extraReducers: {
		//login
		[login.pending]: (state) => {
			state.authLoading = true;
		},
		[login.fulfilled]: (state, { payload }) => {
			state.isAuthenticated = payload.isVerified;
			state.isAdmin = payload.isAdmin;
			state.currentOrganizationId = payload.currentOrganizationId;
			state.currentOrganizationIds = payload.currentOrganizationIds;
			state.user = { email: payload.email, name: payload.name };
			state.token = payload.token;
			state.authLoading = false;
			state.error = null;
		},
		[login.rejected]: (state, { payload }) => {
			state.error = payload;
			state.authLoading = false;
		},

		[signUp.pending]: (state) => {
			state.authLoading = true;
		},
		[signUp.fulfilled]: (state) => {
			state.authLoading = false;
		},
		[signUp.rejected]: (state, { payload }) => {
			state.error = payload;
			state.authLoading = false;
		},
		//verify Token
		[verifyToken.pending]: (state) => {
			state.authLoading = true;
			state.isAuthenticated = false;
		},
		[verifyToken.fulfilled]: (state, { payload }) => {
			state.isAuthenticated = payload.isVerified;
			state.isAdmin = payload.isAdmin;

			state.currentOrganizationId = payload.isAdmin
				? state?.currentOrganizationIds[0] || null
				: payload?.currentOrganizationIds[0] || null;
			state.user = { email: payload.email, name: payload.name };
			state.authLoading = false;
			state.error = null;
		},
		[verifyToken.rejected]: (state, { payload }) => {
			state.isAuthenticated = false;
			state.authLoading = false;
			state.isAdmin = false;
			state.currentOrganizationId = null;
			state.user = null;
			state.error = payload;
		},
		//Verfiy SignUp
		[verifySignUpToken.pending]: (state) => {
			state.authLoading = true;
		},
		[verifySignUpToken.fulfilled]: (state, { payload }) => {
			state.user = payload;
			state.authLoading = false;
			state.error = null;
		},
		[verifySignUpToken.rejected]: (state, { payload }) => {
			state.isAuthenticated = false;
			state.isAdmin = false;
			state.currentOrganizationId = null;
			state.user = null;
			state.error = payload;
			state.authLoading = false;
		},
		[forgotUserPassword.pending]:(state)=>{
			state.authLoading = true
		},
		[forgotUserPassword.fulfilled]:(state)=>{
			state.authLoading = false
		},
		[forgotUserPassword.rejected]:(state,{payload})=>{
			state.isAuthenticated = false;
			state.isAdmin = false;
			state.currentOrganizationId = null;
			state.user = null;
			state.error = payload;
			state.authLoading = false;
		}
	},
});

// Action creators are generated for each case reducer function

export const { authenticate, setOrganizationId, handleLogout } =
	authSlice.actions;

export default authSlice.reducer;
