import {
	Button,
	Divider,
	Flex,
	List,
	Select,
	Spin,
	Tag,
	Typography,
} from "antd";
import { FlagOutlined } from "@ant-design/icons";
import { Campaigns } from "../../utils/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateOrganization } from "../../redux/organizationSlice";
const { Title, Text } = Typography;

const CreateCampaignMapping = () => {
	const dispatch = useDispatch();
	const {
		organizationData,
		serviceTitanCampaigns,
		loading,
		serviveTitanLoading,
	} = useSelector((state) => state?.organization);
	const [campaignsData, setCampaignsData] = useState(
		organizationData?.campaigns_mapping || []
	);

	useEffect(()=>{
		setCampaignsData(organizationData?.campaigns_mapping || [])
	},[organizationData?.campaigns_mapping])
	const handleChange = (medium, source, val) => {
		const STCampaign = serviceTitanCampaigns?.find((e) => e.id === val);
		let campaigns = [...campaignsData];
		let campaignIndex = campaigns?.findIndex(
			(e) =>
				e.whatConverts_medium === medium && e.whatConverts_source === source
		);
		const campaign = {
			whatConverts_medium: medium,
			whatConverts_source: source,
			st_campaign_id: val,
			st_campaign_name: STCampaign?.name || "",
		};
		if (campaignIndex === -1) {
			campaigns.push(campaign);
		} else {
			campaigns[campaignIndex] = { ...campaign };
		}
		setCampaignsData([...campaigns]);
	};

	const handleSubmit = () => {
		dispatch(
			updateOrganization({
				id: organizationData._id,
				payload: { campaigns_mapping: campaignsData },
			})
		);
	};
	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CampaignsList =
		serviceTitanCampaigns?.map((item) => {
			return {
				value: item?.id,
				label: item?.name,
			};
		}) || [];
	return (
		<>
			<Spin spinning={loading || serviveTitanLoading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<FlagOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Campaign Mappings
				</Title>
			</Flex>

			<Flex align="center" justify="flex-end" style={{ margin: "25px 0px" }}>
				{/* <Title level={3}>Mapped List</Title> */}
				<Button type="primary" size="large" onClick={handleSubmit}>
					Save Created List
				</Button>
			</Flex>

			<List
				size="large"
				bordered
				dataSource={Campaigns}
				renderItem={(item) => (
					<>
			   
						{item?.sources?.map((source) => {
							const selectedCampaign = campaignsData.find(
								(e) =>
									e.whatConverts_medium === item.medium &&
									e.whatConverts_source === source.source
							);
							return (
								<List.Item key={source.id}>
									<Flex
										style={{ width: "100%" }}
										align="center"
										justify="space-between"
									>
										<Flex style={{ width: "80%" }} align="center">
											<Flex style={{ width: "100px" }} gap={20}>
												<Tag color={item.colorCode} style={{ width: "100%" }}>
													{item.trafficSource}
												</Tag>
											</Flex>
											<Flex style={{ margin: "25px" }}>{source.logo}</Flex>

											<Flex style={{ width: "150px" }} gap={20}>
												<Title level={5} style={{ margin: "0px" }}>
													{source.source}
												</Title>
											</Flex>
											<Flex style={{ width: "50%" }}>
												<Divider>
													<Text type="secondary">maps to</Text>
												</Divider>
											</Flex>
											<Flex style={{ width: "250px", marginLeft: "100px" }}>
												<Select
													style={{
														width: 300,
													}}
													allowClear
													onChange={(e) =>
														handleChange(item.medium, source.source, e)
													}
													value={
														selectedCampaign
															? selectedCampaign?.st_campaign_name
															: ""
													}
													options={CampaignsList}
													filterOption={filterOption}
													showSearch
												/>
											</Flex>
										</Flex>
									</Flex>
								</List.Item>
							);
						})}
					</>
				)}
			/>
		</>
	);
};

export default CreateCampaignMapping;
