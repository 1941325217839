import { Flex, Input, InputNumber, Switch, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;

const DispatchText = ({
	id,
	dispatch_fee,
	isDefault,
	header,
	body,
	checkboxText,
	onChange,
}) => {
	const { organizationData } = useSelector((state) => state?.organization);

	const handleDispatchToggle = (e, id) => {
		if (e === false) {
			const dispatchObj = {
				dispatch_header:
					header?.trim() === ""
						? organizationData?.default_dispatch_header
						: header,
				dispatch_body:
					body?.trim() === "" ? organizationData?.default_dispatch_body : body,
				dispatch_text:
					checkboxText?.trim() === ""
						? organizationData?.default_dispatch_text
						: checkboxText,
			};
			onChange(
				{
					use_default_dispatch: e,
					dispatch_header: dispatchObj?.dispatch_header,
					dispatch_body: dispatchObj?.default_dispatch_body,
					dispatch_text: dispatchObj?.dispatch_text,
				},
				id
			);
		} else {
			onChange({ use_default_dispatch: e }, id);
		}
	};

	return (
		<>
			<Flex style={{ width: "100%" }} gap={20}>
				<Flex vertical gap={10}>
					<Text type="secondary" strong underline>
						Dispatch Fee
					</Text>
					<InputNumber
						size="middle"
						addonBefore="$"
						defaultValue={0}
						min={0}
						value={dispatch_fee}
						onChange={(e) => onChange({ dispatch_fee: e }, id)}
						placeholder="Dispatch Fee"
						style={{ width: 150 }}
					/>
				</Flex>

				{dispatch_fee > 0 ? (
					<Flex vertical style={{ minWidth: "max-content" }} gap={10}>
						<Text type="secondary" strong underline>
							Use Default Dispatch Text
						</Text>
						<Switch
							checked={isDefault}
							style={{ width: 35 }}
							onChange={(e) => {
								handleDispatchToggle(e, id);
							}}
						/>
					</Flex>
				) : null}
			</Flex>
			<Flex vertical gap={10}>
				{dispatch_fee > 0 ? (
					<Tooltip
						title="Use {{dispatch_fee}} to show dispatch fee in the header"
						placement="bottomLeft"
					>
						<Flex vertical gap={10} style={{ width: "100%" }}>
							<Text type="secondary" strong underline>
								Dispatch Header
							</Text>
							<Input
								size="middle"
								placeholder="Custom Dispatch Heading"
								maxLength={80}
								showCount
								disabled={isDefault}
								value={
									!isDefault
										? header
										: organizationData?.default_dispatch_header
								}
								onChange={(e) =>
									onChange({ dispatch_header: e.target.value }, id)
								}
								style={{
									width: "100%",
								}}
							/>
						</Flex>
					</Tooltip>
				) : null}
				{dispatch_fee > 0 ? (
					<Tooltip
						title="Use {{dispatch_fee}} to show dispatch fee in the header"
						placement="bottomLeft"
					>
						<Flex vertical gap={10} style={{ width: "100%" }}>
							<Text type="secondary" strong underline>
								Dispatch Body
							</Text>
							<Input
								size="middle"
								placeholder="Custom Dispatch Text"
								allowClear
								disabled={isDefault}
								maxLength={150}
								showCount
								value={
									!isDefault ? body : organizationData?.default_dispatch_body
								}
								onChange={(e) =>
									onChange({ dispatch_body: e.target.value }, id)
								}
								style={{
									width: "100%",
								}}
							/>
						</Flex>
					</Tooltip>
				) : null}

				{dispatch_fee > 0 ? (
					<Tooltip
						title="Use {{dispatch_fee}} to show dispatch fee in the header"
						placement="bottomLeft"
					>
						<Flex vertical gap={10} style={{ width: "100%" }}>
							<Text type="secondary" strong underline>
								Dispatch CheckBox Text
							</Text>
							<Input
								size="middle"
								placeholder="Custom Dispatch Text"
								allowClear
								disabled={isDefault}
								maxLength={25}
								showCount
								value={
									!isDefault
										? checkboxText
										: organizationData?.default_dispatch_text
								}
								onChange={(e) =>
									onChange({ dispatch_text: e.target.value }, id)
								}
								style={{
									width:"100%",
								}}
							/>
						</Flex>
					</Tooltip>
				) : null}
			</Flex>
		</>
	);
};

export default DispatchText;
