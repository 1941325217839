import {
	Button,
	Col,
	Collapse,
	Divider,
	Flex,
	InputNumber,
	List,
	Row,
	Select,
	Spin,
	Switch,
	Tag,
	Typography,
	theme,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	updateJobMappings,
	updateOrganization,
} from "../../redux/organizationSlice";
import DispatchText from "./dispatchText";
import JobMapping from "./jobMapping";

const { Title, Text } = Typography;

const CreateJobMapping = () => {
	const dispatch = useDispatch();
	const { token } = theme.useToken();
	const {
		organizationData,
		serviceTitanBusinessUnits,
		serviceTitanJobs,
		loading,
	} = useSelector((state) => state?.organization);
	
	const { trades = [], services = [] } = useSelector((state) => state?.trade);
	const { jobs = [] } = useSelector((state) => state?.jobs);
	const [offeredJobs, setOfferedJobs] = useState([]);
	const [isStateUpdate, setIsStateUpdate] = useState(false);
	const [selectedTrade, setSelectedTrade] = useState("");
	const [serviceType, setServiceType] = useState("");
	const [jobItemsData, setJobItemsData] = useState([]);

	const onChange = (value, jobItemId) => {
		const jobExists = jobItemsData.findIndex((e) => e?.jobItem === jobItemId);
		if (jobItemsData?.length && value && jobExists !== -1) {
			setJobItemsData(
				jobItemsData.map((item) => {
					if (item?.jobItem === jobItemId) {
						return {
							...item,
							...value,
						};
					} else {
						return item;
					}
				})
			);
		} else {
			const data = {
				jobItem: jobItemId,
				...value,
			};
			setJobItemsData((jobItemsData) => [...jobItemsData, data]);
		}
	};

	const onSave = () => {
		dispatch(
			updateJobMappings({ selectedTrade, services, serviceType, jobItemsData })
		);
		setIsStateUpdate(true);
	};

	useEffect(() => {
		if (selectedTrade && serviceType && organizationData) {
			const data =
				organizationData?.jobs_mapping?.find(
					(e) => e.trade === selectedTrade
				)?.[serviceType] || [];
			setJobItemsData([...data]);
			const jobsData = jobs.filter(
				(item) =>
					item?.trade?._id === selectedTrade &&
					item?.serviceType?.toLowerCase() === serviceType?.toLowerCase()
			);
			setOfferedJobs(jobsData);
		}
		if (isStateUpdate) {
			dispatch(
				updateOrganization({
					id: organizationData._id,
					payload: {
						jobs_mapping: organizationData?.jobs_mapping,
					},
				})
			);
			setIsStateUpdate(false);
		}
	}, [
		dispatch,
		organizationData,
		selectedTrade,
		serviceType,
		isStateUpdate,
		jobs,
	]);

	// Filter `option.label` match the user type `input`
	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const getAssociatedQuestions = (item) => {
		const Questions = [];
		organizationData?.questions?.forEach((question) => {
			const jobMatched = question?.associateJobs.findIndex(
				(e) =>
					e?.trade?.tradeName.toLowerCase() === item?.trade?.tradeName?.toLowerCase() &&
					e?.serviceType.findIndex(
						(ser) => ser.toLowerCase() === item?.serviceType?.toLowerCase()
					) !== -1 &&
					e?.job?.toLowerCase() === item?.jobItem?.toLowerCase()
			);
			if (jobMatched !== -1) {
				const questionObj = {
					key: question?.question,
					label: question?.question,
					children: (
						<div style={{ paddingLeft: 24 }}>
							{question?.options?.map((option) => (
								<Tag color="geekblue" key={option}>
									{option?.option}
								</Tag>
							))}
						</div>
					),
					style: {
						marginBottom: 10,
						background: token.colorFillAlter,
						borderRadius: token.borderRadiusLG,
						border: "none",
					},
				};
				Questions.push(questionObj);
			}
		});
		return Questions;
	};


	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Title level={3} style={{ marginBottom: "50px" }}>
				Create Job Mappings
			</Title>

			{/* Auto Bot Section */}
			<Flex vertical gap={20} justify="center">
				<Text type="secondary" strong underline>
					Autobot.ai Trades Offering
				</Text>
				<Flex justify="space-between">
					<Flex vertical gap={10}>
						<Flex gap={20}>
							<Select
								showSearch
								placeholder="Select a Trade"
								optionFilterProp="children"
								onChange={(e) => setSelectedTrade(e)}
								filterOption={filterOption}
								options={trades.map((item) => ({
									label: item.tradeName,
									value: item._id,
								}))}
								size="middle"
								style={{ width: 250 }}
							/>

							<Select
								showSearch
								placeholder="Select Service Type"
								optionFilterProp="children"
								onChange={(e) => setServiceType(e)}
								filterOption={filterOption}
								options={services.map((item) => ({
									label: item.name,
									value: item.id,
								}))}
								size="middle"
								style={{ width: 200 }}
							/>
						</Flex>
						<Text type="danger" strong underline italic>
							* Save your changes before switching trades and service type
						</Text>
					</Flex>
					<Flex vertical align="flex-end">
						<Button
							type="primary"
							style={{ width: "max-content" }}
							onClick={onSave}
							disabled={!selectedTrade || !serviceType}
						>
							Save Mapping
						</Button>
					</Flex>
				</Flex>
			</Flex>
			<Divider orientation="left">Selected trade job items</Divider>

			{/* Service Titan Section */}
			<Flex vertical justify="center" gap={20} style={{ margin: 25 }}>
				{selectedTrade.length && serviceType.length ? (
					offeredJobs?.map((item) => {
						const jobId = item?._id;
						const mappedData = jobItemsData?.find(
							(e) => e.jobItem === item._id
						);

						const AssociatedQuestions = getAssociatedQuestions(item);
						// console.log(AssociatedQuestions, "SS");
						return (
							<Row key={jobId}>
								<Col lg={24} xl={6}>
									<Flex align="center" style={{ height: "100%" }}>
										<Switch
											size="middle"
											checked={mappedData?.is_active}
											style={{ width: 35, marginRight: 20 }}
											onChange={(e) => onChange({ is_active: e }, jobId)}
										/>
										<Text
											strong
											style={{ fontSize: 16, minWidth: 150, marginRight: 5 }}
										>
											{item.jobItem}
										</Text>

										<Flex style={{ width: 150, margin: "0px 5px" }}>
											<Divider>
												<Text type="secondary">Maps To</Text>
											</Divider>
										</Flex>
									</Flex>
								</Col>
								<Col lg={24} xl={{ span: 16, offset: 1 }}>
									<Flex
										align="flex-start"
										gap={10}
										style={{ width: "100%" }}
										vertical
									>
										<JobMapping
											jobId={jobId}
											mappedData={mappedData}
											serviceTitanBusinessUnits={serviceTitanBusinessUnits}
											serviceTitanJobs={serviceTitanJobs}
											filterOption={filterOption}
											onChange={onChange}
										/>
										<Flex style={{ width: "100%" }} gap={20}>
											<Flex vertical  gap={10}>
												<Text type="secondary" strong underline>
													Member Dispatch Fee
												</Text>
												<Flex gap={10}>
												<InputNumber
													size="middle"
													addonBefore="$"
													defaultValue={0}
													min={0}
													value={mappedData?.member_dispatch_fee}
													onChange={(e) => onChange({ "member_dispatch_fee": e }, jobId)}
													placeholder="Dispatch Fee"
													style={{ width: 150 }}
												/>
												</Flex>
											</Flex>
										</Flex>

										<DispatchText
											id={jobId}
											member_dispatch_fee={mappedData?.member_dispatch_fee}
											dispatch_fee={mappedData?.dispatch_fee}
											isDefault={mappedData?.use_default_dispatch}
											header={mappedData?.dispatch_header}
											body={mappedData?.dispatch_body}
											checkboxText={mappedData?.dispatch_text}
											onChange={onChange}
										/>



										{AssociatedQuestions?.length > 0 && (
											<Text type="secondary" strong underline>
												Associated Questions
											</Text>
										)}
										<Flex vertical>
											<Collapse
												bordered={false}
												expandIcon={({ isActive }) => (
													<CaretRightOutlined rotate={isActive ? 90 : 0} />
												)}
												style={{
													background: token.colorBgContainer,
												}}
												items={AssociatedQuestions}
											/>
										</Flex>
									</Flex>
								</Col>
								<Divider />
							</Row>
						);
					})
				) : (
					<List dataSource={[]} />
				)}
			</Flex>
		</>
	);
};

export default CreateJobMapping;
