import React from "react";
import {
	Layout,
	Button,
	Select,
	Typography,
	Flex,
	// Avatar,
	Row,
	Col,
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout, setOrganizationId } from "../../../redux/authSlice";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
const { Text } = Typography;

const DashboardHeader = ({ collapsed, setCollapsed, colorBgContainer }) => {
	const dispatch = useDispatch();

	const {
		isAdmin = false,
		currentOrganizationId,
		currentOrganizationIds,
		user,
	} = useSelector((state) => state?.auth);
	const { organizationsList = [] } = useSelector((state) => state?.admin);

	const filterOption = (input, option) =>{
		console.log(input)
		console.log(option)
		return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
	}
	return (
		<Header
			style={{
				padding: 0,
				background: colorBgContainer,
			}}
		>
			<Row>
				<Col sm={24} lg={1}>
					<Button
						type="text"
						icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
						onClick={() => setCollapsed(!collapsed)}
						style={{
							fontSize: "16px",
							width: 64,
							height: 64,
						}}
					/>
				</Col>
				<Col sm={24} lg={11}>
					{isAdmin ? (
						<Select
							showSearch
							placeholder="Select an organization"
							optionFilterProp="children"
							value={currentOrganizationId}
							onSelect={(e) => {
								dispatch(setOrganizationId(e));
							}}
							filterOption={filterOption}
							options={organizationsList.map((item) => ({
								label: item.organization_name,
								value: item._id,
							}))?.sort((a, b) => a.label.localeCompare(b.label))}
							size="middle"
							style={{ width: 400 }}
						/>
					) : currentOrganizationId ? (
						<Select
						showSearch
						placeholder="Select an organization"
						optionFilterProp="children"
						value={currentOrganizationId}
						onSelect={(e) => {
							dispatch(setOrganizationId(e));
						}}
						filterOption={filterOption}
						options={organizationsList?.filter((item) =>currentOrganizationIds?.includes(item._id)).map((item) => (
							{
								label: item.organization_name,
								value: item._id,
							}
						) )?.sort((a, b) => a.label.localeCompare(b.label))}
						size="middle"
						style={{ width: 400 }}
					/>
					) : null}
				</Col>
				<Col sm={24} lg={12}>
					<Flex
						justify="flex-end"
						align="center"
						style={{ height: "100%", marginRight: 50 }}
						gap={20}
					>
						<Flex vertical justify="flex-end" align="flex-end">
							<Text strong>{user?.email}</Text>
							<Text
								type="danger"
								underline
								strong
								onClick={() => dispatch(handleLogout())}
								style={{ cursor: "pointer" }}
							>
								Sign Out
							</Text>
						</Flex>

						{/* <Avatar
							style={{
								backgroundColor: "#fde3cf",
								color: "#f56a00",
							}}
							size={"large"}
						>
							{user?.email?.substring(0, 1)}
						</Avatar> */}
					</Flex>
				</Col>
			</Row>
		</Header>
	);
};

export { DashboardHeader };