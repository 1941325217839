import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Divider,
  Flex,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Table,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  updateOrganization,
} from "../../../redux/organizationSlice";
import AddCapacityToBU from "./addBusinessUnit";

const { Text } = Typography;

export const newcapcity = {
  associatedBUs: [],
  capacity: [
    { id: "monday", title: "Monday", windows: [] },
    { id: "tuesday", title: "Tuesday", windows: [] },
    { id: "wednesday", title: "Wednesday", windows: [] },
    { id: "thursday", title: "Thursday", windows: [] },
    { id: "friday", title: "Friday", windows: [] },
    { id: "saturday", title: "Saturday", windows: [] },
    { id: "sunday", title: "Sunday", windows: [] },
  ],
};
// export const newcapcity = {
//   associatedBUs: [],
//   capacity: [
//     { id: "weekdays", title: "Monday - Friday", windows: [] },
//     { id: "saturday", title: "Saturday", windows: [] },
//     { id: "sunday", title: "Sunday", windows: [] },
//   ],
// };

const Capacity = () => {
  const dispatch = useDispatch();
  const { organizationData, serviceTitanBusinessUnits } = useSelector(
    (state) => state?.organization
  );

  const [open, setOpen] = useState(false);
  const [BusinessUnits, setBusinessUnits] = useState([]);
  const [capacity, setCapacity] = useState(
    JSON.parse(JSON.stringify(organizationData?.capacityWindows)) || []
  );
  const [capacityWindow, setCapacityWindow] = useState({ ...newcapcity });
  const [isEdit, setIsEdit] = useState(false);

  const handleAddCapacity = () => {
    if (capacityWindow?.associatedBUs?.length) {
      dispatch(
        updateOrganization({
          id: organizationData._id,
          payload: {
            capacityWindows: [...capacity, capacityWindow],
          },
        })
      );
      setOpen(false);
    }
  };

  const handleEditCapacity = () => {
    const updatedCapacity = capacity.map((item) =>
      item._id === capacityWindow._id ? { ...capacityWindow } : item
    );
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: {
          capacityWindows: updatedCapacity,
        },
      })
    );
    setOpen(false);
    setIsEdit(false);
  };

  const handleDeleteCapacity = (item) => {
    const filteredCapacity = capacity.filter((e) => e._id !== item._id);
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: {
          capacityWindows: filteredCapacity,
        },
      })
    );
  };

  const calculateBusinessUnits = (selectedItem) => {
    let associatedBUs = [];
    const remainingBUs = [];

    organizationData?.capacityWindows?.forEach((item) => {
      if (selectedItem && item._id === selectedItem._id) {
        // Skip the selectedItem's associatedBUs
      } else {
        associatedBUs = [...associatedBUs, ...item?.associatedBUs];
      }
    });

    serviceTitanBusinessUnits?.forEach((element) => {
      const ifExists = associatedBUs.findIndex((e) => e === element.id);
      if (ifExists === -1) {
        remainingBUs.push({
          label: element.name,
          value: element.id,
        });
      }
    });

    setBusinessUnits([...remainingBUs]);
  };

  const resetCapacity = useCallback(() => {
    setCapacity(JSON.parse(JSON.stringify(organizationData?.capacityWindows)));
  },[organizationData]);

  useEffect(() => {
    resetCapacity();
  }, [organizationData, resetCapacity]);

  const onAddNewCapacity = () => {
    setCapacityWindow({ ...newcapcity });
    calculateBusinessUnits();
    setIsEdit(false);
    setOpen(true);
  };

  const columns = [
    {
      title: "Business Units",
      dataIndex: "associatedBUs",
      key: "associatedBUs",
      render: (associatedBUs, record) => (
        <AssociatedBusinessUnits
          associatedBusinessUnits={associatedBUs || []}
          serviceTitanBusinessUnits={serviceTitanBusinessUnits}
        />
      ),
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      key: "capacity",
      render: (capacity, record) => (
        <AssociatedCapacity capacity={record.capacity} />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Flex vertical gap={25}>
          <Flex align="center" gap={25}>
            <EditOutlined
              style={{
                fontSize: "20px",
                color: "#1890ff",
                cursor: "pointer",
              }}
              onClick={() => {
                calculateBusinessUnits(record);
                setCapacityWindow(record);
                setIsEdit(true);
                setOpen(true);
              }}
            />
            <Popconfirm
              title="Delete Capacity?"
              description="Are you sure to delete this Capacity?"
              placement="left"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleDeleteCapacity(record)}
              okText="Delete"
              okType="danger"
              cancelText="Cancel"
            >
              <DeleteOutlined
                style={{
                  fontSize: "20px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </Flex>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex style={{ margin: "10px 0px" }} vertical>
        <Flex justify="space-between" style={{ marginBottom: 10 }}>
          <Button
            onClick={onAddNewCapacity}
            style={{ background: "#1890ff", color: "#fff", borderColor: "#1890ff" }}
          >
            Add Capacity
          </Button>
        </Flex>
        <Table
          dataSource={capacity}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => <Divider />,
            rowExpandable: (record) => false,
          }}
        />
      </Flex>

      <Modal
        title="Add Capacity Window"
        open={open}
        width={"80%"}
        style={{ height: "calc(100vh - 300px)"}}
        okText="Save Capacity Window"
        onOk={isEdit ? handleEditCapacity : handleAddCapacity}
        okButtonProps={{
          disabled: capacityWindow?.associatedBUs?.length ? false : true,
          style: { background: "#1890ff", borderColor: "#1890ff" },
        }}
        onCancel={() => {
          setOpen(false);
          resetCapacity();
        }}
      >
        <AddCapacityToBU
          businessUnits={BusinessUnits}
          capacityWindow={capacityWindow}
          setCapacityWindow={setCapacityWindow}
        />
      </Modal>
    </>
  );
};

export default Capacity;

const AssociatedBusinessUnits = ({
  associatedBusinessUnits,
  serviceTitanBusinessUnits,
}) => {
  return (
    <Flex vertical>
      {associatedBusinessUnits.map((BU) => {
        const BusinessUnit = serviceTitanBusinessUnits.find(
          (e) => e.id === BU
        );
        return (
          <Text key={BU} strong style={{ marginBottom: 10 }}>
            {BusinessUnit?.name}
          </Text>
        );
      })}
    </Flex>
  );
};

const AssociatedCapacity = ({ capacity }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        {capacity.map((capacity) => (
          <Col span={8} key={capacity.id}>
            <div style={{ border: "1px solid #e8e8e8", padding: 10 }}>
              <Text strong style={{ color: "#1890ff" }}>
                {capacity.title}
              </Text>
              <div style={{ marginTop: 5 }}>
                {capacity.windows.length ? (
                  capacity.windows.map((win) => (
                    <div
                      key={win.id}
                      style={{ marginBottom: 5, display: "flex", justifyContent:"space-between" }}
                    >
                      <Text>
                        {win.start} - {win.end}
                      </Text>
                      <br />
                      <Text strong>{win.bookingSlots} Jobs</Text>
                    </div>
                  ))
                ) : (
                  <Text type="danger">No Jobs Available</Text>
                )}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};
