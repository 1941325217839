import { Popconfirm, Tag } from "antd";
import React from "react";
import { BiEdit,BiTrash } from "react-icons/bi";

export default function AnswerTag({ deleteQuestion, question, showModal, setAction }) {
  return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    <div style={{ display: "flex", alignItems: "start", flexDirection: "column", gap: 8, justifyItems: "start" }}>
      <div style={{ paddingLeft: 24, display: 'flex', flexWrap: 'wrap' }}>
        {question?.options?.map((option, index) => (
          <Tag color="blue" style={{ fontSize: '13px', borderRadius: '8px' }} key={index}>
            {option?.option}
          </Tag>
        ))}
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: 24, gap: 4 }}>
        {question.associateJobs.map((jobsData, index) => (
          <Tag color="green" style={{ fontSize: '13px', borderRadius: '8px' }} key={index}>
            {jobsData.job}
          </Tag>
        ))}
      </div>
    </div>

    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <BiEdit
        size={24}
        type="primary"
        style={{ marginLeft: "auto", cursor: "pointer", color: "#1890ff" }}
        onClick={() => {
          showModal(question)
          setAction("Edit");
        }}
      />
      <Popconfirm
        title="Delete the question"
        description="Are you sure to delete this question?"
        onConfirm={() => deleteQuestion(question._id)}
        okText="Yes"
        cancelText="No"
      >
        <BiTrash size={24}
          type="danger"
          style={{ marginLeft: "auto", cursor: "pointer", color: "red" }} />
      </Popconfirm>
    </div>
  </div>;
}
