import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Flex, Row, Spin, Typography } from "antd";
import { CloudServerOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { initiateInitialExtraction } from "../../../redux/adminSlice";
import { fetchSTBusinessUnits } from "../../../redux/organizationSlice";
const { Title, Text } = Typography;

const ServiceTitanDetails = () => {
	const dispatch = useDispatch();
	const {
		organizationData,
		serviceTitanBusinessUnits,
		serviceTitanJobs,
		loading,
		serviveTitanLoading,
	} = useSelector((state) => state?.organization);
	const { isAdmin, currentOrganizationId } = useSelector(
		(state) => state?.auth
	);

	useEffect(() => {
		dispatch(fetchSTBusinessUnits(currentOrganizationId));
	}, [dispatch, currentOrganizationId]);

	return (
		<>
			<Spin spinning={loading || serviveTitanLoading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<CloudServerOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Service Titan Business Units & Jobs
				</Title>
			</Flex>

			{isAdmin ? (
				<Button
					type="primary"
					style={{ marginBottom: 50 }}
					onClick={() =>
						dispatch(initiateInitialExtraction(organizationData._id))
					}
					loading={loading || serviveTitanLoading}
					disabled={loading || serviveTitanLoading}
				>
					Sync Data
				</Button>
			) : null}

			<Row>
				<Col span={6}>
					<Flex vertical>
						<Title level={4} underline style={{ paddingBottom: 20 }}>
							Business Units
						</Title>
						{serviceTitanBusinessUnits?.map((businessUnit, index) => {
							return (
								<Text
									type="secondary"
									key={index}
									strong
									style={{ paddingTop: 10 }}
								>
									{businessUnit?.name}
								</Text>
							);
						})}
					</Flex>
				</Col>
				<Col span={12}>
					<Flex vertical>
						<Title level={4} underline style={{ paddingBottom: 20 }}>
							Job Types
						</Title>
						<Flex vertical>
							<Flex vertical style={{ paddingBottom: 15 }}>
								{serviceTitanJobs?.map((job, index) => {
									return (
										<Text
											type="secondary"
											key={index}
											strong
											style={{ paddingTop: 10 }}
										>
											{job?.name}
										</Text>
									);
								})}
							</Flex>
						</Flex>
					</Flex>
				</Col>
			</Row>
		</>
	);
};

export default ServiceTitanDetails;
