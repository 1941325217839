import { Col, Row, Typography, Space, Alert } from "antd";
import MoveJobs from "./MoveJobs";
import RenameJobs from "./CapacityOperations";
import AssingJobs from "./AssignJobs";
import ChangeOrder from "./ChangeCapacityOrder";
import Title from "antd/es/typography/Title";

const { Paragraph } = Typography;

export default function NavigationsFlow() {
  return (
    <>
      <Title
        level={3}
        style={{ textAlign: "start", marginBottom: "24px", color: "#333" }}
      >
        Navigation Flows
      </Title>
      <Row justify="center" style={{ padding: "20px" }}>
        <Col xs={24} md={20} lg={18}>
          <Alert
            message="Suggestions for Moving Jobs"
            description={
              <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>
                  <strong>
                    If you want to move jobs across the same service type (like in
                    repair but in different folders), use the <i>Assign Jobs</i>{" "}
                    feature to simply unselect from one folder and select in
                    another.
                  </strong>
                </Paragraph>
                <Paragraph>
                  <strong>
                    If you want to move jobs across different service types (like
                    from repair to maintenance), ensure that the job is not
                    already present in the target service type. You can move a job
                    first using the <i>Move Jobs</i> feature, then manage it
                    further using the <i>Assign Jobs</i> feature.
                  </strong>
                </Paragraph>
                <Paragraph>
                  <strong>
                    If you want to move jobs across different service types (like
                    from repair to maintenance) where that job doesn't exist and you do not want to remove it from existing folder then first move job to that service type using <i>Move Jobs</i> feature and then assign job to source service folder again using <i>Assign Jobs</i> feature. 
                  </strong>
                </Paragraph>
                <Paragraph>
                  <strong>
                    Only move jobs to categories that itself are not jobs.
                  </strong>
                </Paragraph>
                <Paragraph>
                  <strong>Verification:</strong> After moving jobs, verify the{" "}
                  <i>Job Mappings</i>.
                </Paragraph>
              </Space>
            }
            type="info"
            showIcon
            style={{
              marginBottom: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          />
        </Col>
        <Col xs={24} md={20} lg={18}>
          <MoveJobs />
          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={24} lg={12}>
              <RenameJobs />
            </Col>
            <Col xs={24} lg={12}>
              <AssingJobs />
            </Col>
          </Row>
          <ChangeOrder />
        </Col>
      </Row>
    </>
  );
}
