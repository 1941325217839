const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const GREY_DIM = "#686868";

export const styles = {
	zone: {
		alignItems: "center",
		border: `2px dashed ${GREY}`,
		borderRadius: 20,
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		padding: 20,
	},
	file: {
		background: "linear-gradient(to bottom, #EEE, #DDD)",
		borderRadius: 20,
		display: "flex",
		height: 120,
		width: 120,
		position: "relative",
		zIndex: 10,
		flexDirection: "column",
		justifyContent: "center",
	},
	info: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		paddingLeft: 10,
		paddingRight: 10,
	},
	size: {
		backgroundColor: GREY_LIGHT,
		borderRadius: 3,
		marginBottom: "0.5em",
		justifyContent: "center",
		display: "flex",
	},
	name: {
		backgroundColor: GREY_LIGHT,
		borderRadius: 3,
		fontSize: 12,
		marginBottom: "0.5em",
	},
	progressBar: {
		bottom: 14,
		position: "absolute",
		width: "100%",
		paddingLeft: 10,
		paddingRight: 10,
	},
	zoneHover: {
		borderColor: GREY_DIM,
	},
	default: {
		borderColor: GREY,
	},
	remove: {
		height: 23,
		position: "absolute",
		right: 6,
		top: 6,
		width: 23,
	},
};
