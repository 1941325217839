import React from "react";
import { Button, Flex, Result, Typography } from "antd";
const { Text } = Typography;
const NotFound = () => (
	<Flex
		justify="center"
		align="center"
		style={{ width: "100vw", height: "100vh" }}
	>
		<Result
			status="404"
			title="Error !"
			// subTitle="Appologies, something unexpected happened on our side. Let's try again"
			extra={
				<Flex vertical justify="center" align="center" gap={20}>
					<Text>Appologies, Something unexpected happened on our side.</Text>
					<Text>Let's try again.</Text>
					<Button
						type="primary"
						onClick={() => window.location.reload("/")}
						style={{ width: "fit-content" }}
					>
						Reload{" "}
					</Button>
				</Flex>
			}
		/>
	</Flex>
);
export default NotFound;
