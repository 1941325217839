import { Tabs } from "antd";
import React, { useEffect } from "react";
import { JobTabs } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchSTBusinessUnits } from "../../redux/organizationSlice";

const Jobs = () => {
	const dispatch = useDispatch();
	const { currentOrganizationId } = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(fetchSTBusinessUnits(currentOrganizationId));
	}, [dispatch, currentOrganizationId]);

	return (
		<>
			<Tabs
				items={JobTabs.map((item) => {
					return {
						key: item.name,
						label: (
							<span>
								{item.icon} {item.name}
							</span>
						),
						children: item.component,
					};
				})}
			/>
			{/* <JobsListing /> */}
		</>
	);
};

export default Jobs;
