import { Button, Card, Select, Space, Spin, Input, Radio, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrganization } from '../../../../redux/organizationSlice';

const { Option } = Select;

export default function RenameJobs() {
    const { organizationData, loading } = useSelector((state) => state.organization);
    const { services } = useSelector((state) => state.trade);
    const dispatch = useDispatch();

    const [selectedTrade, setSelectedTrade] = useState(null);
    const [selectedServiceType, setSelectedServiceType] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryHeading, setNewCategoryHeading] = useState('');
    const [trades, setTrades] = useState([]);
    const [categories, setCategories] = useState([]);
    const [usePreviousValues, setUsePreviousValues] = useState(false);

    useEffect(() => {
        if (organizationData) {
            setTrades(organizationData.navigation_mapping.map((item) => ({
                id: item.trade.id,
                name: item.trade.name
            })));
            resetForm();
        }
    }, [organizationData]);

    useEffect(() => {
        if (selectedTrade && selectedServiceType) {
            const selectedTradeData = organizationData.navigation_mapping.find(item => item.trade.id === selectedTrade);
            if (selectedTradeData) {
                setCategories(selectedTradeData[selectedServiceType] || []);
            }
        } else {
            setCategories([]);
        }
    }, [selectedTrade, selectedServiceType, organizationData]);

    useEffect(() => {
        if (selectedCategory) {
            const selectedCategoryData = categories.find(category => category._id === selectedCategory);
            if (selectedCategoryData) {
                if (usePreviousValues) {
                    setNewCategoryName(selectedCategoryData.category_name);
                    setNewCategoryHeading(selectedCategoryData.heading);
                }
                else{
                    setNewCategoryName('');
                    setNewCategoryHeading('');
                }
            }
        }
    }, [selectedCategory, categories, usePreviousValues]);

    const resetForm = () => {
        setSelectedCategory(null);
        setSelectedServiceType(null);
        setSelectedTrade(null);
        setNewCategoryName('');
        setNewCategoryHeading('');
        setCategories([]);
    };

    const handleTradeChange = (value) => {
        setSelectedTrade(value);
        setSelectedServiceType(null);
        setSelectedCategory(null);
        setNewCategoryName('');
        setNewCategoryHeading('');
    };

    const handleServiceTypeChange = (value) => {
        setSelectedServiceType(value);
        setSelectedCategory(null);
        setNewCategoryName('');
        setNewCategoryHeading('');
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const renameCategory = () => {
        if (!selectedTrade || !selectedServiceType || !selectedCategory || !newCategoryName.trim()) {
            alert("Please select all fields and enter a new category name.");
            return;
        }

        const updatedOrganizationData = structuredClone(organizationData);
        const trade = updatedOrganizationData.navigation_mapping.find(item => item.trade.id === selectedTrade);
        const category = trade[selectedServiceType].find(category => category._id === selectedCategory);

        if (category) {
            category.category_name = newCategoryName;
            category.heading = newCategoryHeading;
            dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData }));
        } else {
            alert("Category not found.");
        }
    };

    const addCategory = () => {
        if (!selectedTrade || !selectedServiceType || !newCategoryName.trim()) {
            alert("Please select all fields and enter a new category name.");
            return;
        }

        const updatedOrganizationData =structuredClone(organizationData);
        const trade = updatedOrganizationData.navigation_mapping.find(item => item.trade.id === selectedTrade);
        
        const newCategory = {
            category_name: newCategoryName,
            is_job: false,
            is_Active: true,
            heading: newCategoryHeading,
            jobs: []
        };

        const isAlreadyPresent = trade[selectedServiceType].find(category => category.category_name === newCategoryName);

        if(isAlreadyPresent){
            notification.error({
                message: "Error",
                description: "Category already exists.",
                duration: 3,
            });
            return;
        }
        trade[selectedServiceType].push(newCategory);
        dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData }));
    };

    const removeCategory = () => {
        if (!selectedTrade || !selectedServiceType || !selectedCategory) {
            alert("Please select all fields to remove the category.");
            return;
        }

        const updatedOrganizationData = structuredClone(organizationData);
        const trade = updatedOrganizationData.navigation_mapping.find(item => item.trade.id === selectedTrade);
        const categoryIndex = trade[selectedServiceType].findIndex(category => category._id === selectedCategory);

        if (categoryIndex > -1) {
            trade[selectedServiceType].splice(categoryIndex, 1);
            dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData }));
        } else {
            alert("Category not found.");
        }
    };

    return (
        <>
            <Spin spinning={loading} fullscreen />
            <div style={{ padding: '24px' }}>
                <Card
                    title="Rename/Add/Remove Categories"
                    bordered={false}
                    style={{
                        width: '100%',
                        marginBottom: 24,
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: '10px',
                    }}
                    headStyle={{ textAlign: 'center', color: '#555', fontWeight: 'bold' }}
                >
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Select
                            placeholder="Select Trade"
                            onChange={handleTradeChange}
                            style={{ width: '100%' }}
                            value={selectedTrade}
                        >
                            {trades.map((trade) => (
                                <Option key={trade.id} value={trade.id}>{trade.name}</Option>
                            ))}
                        </Select>

                        <Select
                            placeholder="Select Service Type"
                            onChange={handleServiceTypeChange}
                            style={{ width: '100%' }}
                            value={selectedServiceType}
                            disabled={!selectedTrade}
                        >
                            {services.map((type) => (
                                <Option key={type.id} value={type.id}>{type.name.charAt(0).toUpperCase() + type.name.slice(1)}</Option>
                            ))}
                        </Select>

                        <Select
                            placeholder="Select Category"
                            onChange={handleCategoryChange}
                            style={{ width: '100%' }}
                            value={selectedCategory}
                            disabled={!selectedServiceType}
                        >
                            {categories.map((category) => (
                                <Option key={category._id} value={category._id}>{category.category_name}</Option>
                            ))}
                        </Select>

                        <Input
                            placeholder="Enter New Category Name"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            style={{ width: '100%' }}
                        />

                        <Input
                            placeholder="Enter New Category Heading"
                            value={newCategoryHeading}
                            onChange={(e) => setNewCategoryHeading(e.target.value)}
                            style={{ width: '100%' }}
                        />

                        <Radio.Group
                            onChange={(e) => setUsePreviousValues(e.target.value)}
                            value={usePreviousValues}
                            style={{ marginBottom: '16px' }}
                        >
                            <Radio value={false}>Use New Values</Radio>
                            <Radio value={true}>Use Previous Values</Radio>
                        </Radio.Group>

                        <Button
                            onClick={renameCategory}
                            style={{ width: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                            disabled={!selectedCategory || !newCategoryName.trim()}
                        >
                            Rename Category
                        </Button>

                        <Button
                            onClick={addCategory}
                            style={{ width: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                            disabled={!selectedTrade || !selectedServiceType || !newCategoryName.trim() || usePreviousValues}
                        >
                            Add Category
                        </Button>

                        <Button
                            onClick={removeCategory}
                            style={{ width: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                            disabled={!selectedTrade || !selectedServiceType || !selectedCategory}
                        >
                            Remove Category
                        </Button>
                    </Space>
                </Card>
            </div>
        </>
    );
}
