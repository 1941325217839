import { Table, DatePicker, Spin, Button, Space} from "antd";
import React, { useState, useEffect, useRef } from "react";

import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import {
  FileExcelOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import axiosInstance from "../../../redux/apiAxios";

const { RangePicker } = DatePicker;

const FollowUpLeads = () => {
  const { organizationData } = useSelector((state) => state.organization);
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [defaultRange, setDefaultRange] = useState([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 25,
      total: 0,
      showSizeChanger: false,
    },
  });

  useEffect(() => {
    fetchFollowUpLeads(defaultRange[0], defaultRange[1]);
  }, [organizationData]);

  const fetchFollowUpLeads = async (startDate, endDate, page = 1) => {
    setFetchingData(true);
    try {
      const response = await axiosInstance.post("/org/fetch/followup", {
        organizationId: organizationData._id,
        type: "FollowUp",
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });

      const transformedData = response.data.map((item) => ({
        customerId: item.customerId,
        customerName: item.customerName,
        customerPhone: item.customerPhone,
        customerEmail: item.customerEmail,
        type: item.type,
        createdOn: formatDate(item.createdOn), // Date transformation
      }));

      setData(transformedData);
      setTableParams((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          current: page,
          total: response.data.total,
        },
      }));
      setFetchingData(false);
    } catch (error) {
      console.error("Error fetching follow-up leads:", error);
      setFetchingData(false);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format("MMM D, YYYY h:mm A");
  };

  const handleDateChange = async (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDefaultRange([start, end]);
      setFetchingData(true);

      try {
        await fetchFollowUpLeads(start, end, 1);
      } catch (error) {
        console.error("Error fetching follow-up leads:", error);
        setFetchingData(false);
      }
    } else {
      setData([]);
    }
  };

  const handleTableChange = async (pagination) => {
    fetchFollowUpLeads(defaultRange[0], defaultRange[1], pagination.current);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FollowUpLeads");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataBlob, `FollowUpLeads_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`);
  };

  const reloadData = () => {
    fetchFollowUpLeads(
      defaultRange[0],
      defaultRange[1],
      tableParams.pagination.current
    );
  };

  const columns = [
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      align: "center",
    },
    {
      title: "Customer ID",
      dataIndex: "customerId",
      key: "customerId",
      align: "center",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      align: "center",
    },
  ];

  return (
    <Spin spinning={fetchingData}>
      <Space style={{ marginBottom: 16 }}>
        <RangePicker
          allowClear={false}
          value={defaultRange}
          onChange={handleDateChange}
          format="MMM D, YYYY"
        />
        <Button
          icon={<ReloadOutlined />}
          onClick={reloadData}
        >
          Reload
        </Button>
        <Button
          icon={<FileExcelOutlined />}
          onClick={exportToExcel}
          loading={fetchingData}
        >
          Export to Excel
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={data}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        rowKey="customerId"
      />
    </Spin>
  );
};

export default FollowUpLeads;
