import { useState } from "react";
import { Button, Col, Flex, Input, Radio, Row, Spin, Typography } from "antd";
import { PartitionOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createJob } from "../../redux/jobsSlice";
import JobsOfferingListing from "./JobOfferingsListing";

const { Title } = Typography;

const AdminJobOfferings = () => {
	const dispatch = useDispatch();
	const { trades, services, loading } = useSelector((state) => state.trade);

	const [jobItem, setJobItem] = useState("");
	const [tradeId, setTradeId] = useState("");
	const [serviceType, setServiceType] = useState("");

	const addJobType = async () => {
		if (jobItem && tradeId && serviceType) {
			const values = {
				tradeId,
				jobItem,
				serviceType,
			};
			const result = dispatch(createJob({ values }));
			if (result.type === "job/create/fulfilled") {
				setJobItem("");
			}
		}
	};

	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<PartitionOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Jobs Items
				</Title>
			</Flex>
			<Row>
				<Col span={6}>
					<Flex vertical={true} gap={20}>
						<Input
							placeholder="Add New JobType"
							value={jobItem}
							onChange={(e) => setJobItem(e.target.value)}
							size="large"
						/>
						<Radio.Group
							options={trades.map((trade) => ({
								label: trade.tradeName,
								value: trade._id,
							}))}
							onChange={(e) => {
								setTradeId(e.target.value);
							}}
						/>
						<Radio.Group
							options={services.map((service) => ({
								label: service.name,
								value: service.id,
							}))}
							onChange={(e) => {
								setServiceType(e.target.value);
							}}
						/>

						<Button
							type="primary"
							onClick={addJobType}
							disabled={
								jobItem.trim() === "" || tradeId === "" || serviceType === ""
							}
							size="large"
							style={{ width: "fit-content" }}
						>
							Submit
						</Button>
					</Flex>
				</Col>
			</Row>

			<Flex style={{ margin: "25px 0px" }}>
				<Title level={3}>Offered Jobs</Title>
			</Flex>
			<JobsOfferingListing />
		</>
	);
};

export default AdminJobOfferings;
