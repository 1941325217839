import { Button, Card, Select, Row, Col, Typography, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrganizationJobs } from '../../../../redux/organizationSlice';


const { Option } = Select;
const { Title } = Typography;

export default function MoveJobs() {
    const { organizationData, loading } = useSelector((state) => state.organization);
    const { jobs: jobList = [] } = useSelector((state) => state.jobs);
    const { services, trades: backendTrades } = useSelector((state) => state.trade);

    const dispatch = useDispatch();

    const [selectedTrade, setSelectedTrade] = useState(null);
    const [selectedServiceType, setSelectedServiceType] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [targetServiceType, setTargetServiceType] = useState(null);
    const [targetCategory, setTargetCategory] = useState(null);
    const [trades, setTrades] = useState([]);
    const [categories, setCategories] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [targetCategories, setTargetCategories] = useState([]);

    useEffect(() => {
        handleReset();
    }, [organizationData]);

    const handleReset = () => {
        setSelectedJob(null);
        setSelectedCategory(null);
        setSelectedServiceType(null);
        setSelectedTrade(null);
        setTargetCategory(null);
        setTargetServiceType(null);
        setTrades(
            organizationData?.navigation_mapping?.map((item) => ({
                id: item.trade.id,
                name: item.trade.name,
            })) ?? []
        );
        setCategories([]);
        setJobs([]);
        setTargetCategories([]);
    };

    const handleTradeChange = (value) => {
        setSelectedTrade(value);
        setSelectedServiceType(null);
        setSelectedCategory(null);
        setSelectedJob(null);
        const selectedTradeData = organizationData.navigation_mapping?.find((item) => item.trade.id === value);
        setCategories([]);
        setJobs([]);
        setTargetCategories([]);
    };

    const handleServiceTypeChange = (value) => {
        setSelectedServiceType(value);
        setSelectedCategory(null);
        setSelectedJob(null);
        const selectedTradeData = organizationData.navigation_mapping?.find((item) => item.trade.id === selectedTrade);
        setCategories(selectedTradeData[value] ?? []);
        setJobs([]);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        setSelectedJob(null);
        const selectedCategoryData = categories.find((category) => category._id === value);
        setJobs(selectedCategoryData?.jobs ?? []);
    };

    const handleJobChange = (value) => {
        setSelectedJob(value);
    };

    const handleTargetServiceTypeChange = (value) => {
        setTargetServiceType(value);
        setTargetCategory(null);
        const selectedTradeData = organizationData.navigation_mapping?.find((item) => item.trade.id === selectedTrade);
        setTargetCategories(selectedTradeData[value] ?? []);
    };

    const handleTargetCategoryChange = (value) => {
        setTargetCategory(value);
    };

    const moveJob = () => {
        if (!selectedTrade || !selectedServiceType || !selectedCategory || !selectedJob || !targetServiceType || !targetCategory) {
            notification.error({
                message: "Error",
                description: "Please select all fields to move the job.",
            });
            return;
        }

        const updatedOrganizationData = JSON.parse(JSON.stringify(organizationData));
        const sourceTrade = updatedOrganizationData.navigation_mapping?.find(item => item.trade.id === selectedTrade);

        const sourceCategoryData = sourceTrade[selectedServiceType].find(category => category._id === selectedCategory);
        const targetCategoryData = sourceTrade[targetServiceType].find(category => category._id === targetCategory);

        const jobIndex = sourceCategoryData.jobs.indexOf(selectedJob);
        if (jobIndex > -1) {
            const isJobInTarget = targetCategoryData.jobs.includes(selectedJob);
            if (isJobInTarget) {
                notification.error({
                    message: "Error",
                    description: "Job already exists in the target category.",
                });
                return;
            } 
            sourceCategoryData.jobs.splice(jobIndex, 1);
            targetCategoryData.jobs.push(selectedJob);

            const sourceTradeData = backendTrades.find((trade) => trade?.tradeName === sourceTrade?.trade.name);
            const selectedJobData = jobList.find((job) => job.jobItem === selectedJob && job?.trade?._id === sourceTradeData._id && job?.serviceType === selectedServiceType);

            const data = {
                selectedJob: selectedJob,
                selectedJobId: selectedJobData._id,
                selectedServiceType,
                targetType: targetServiceType,
                tradeData: sourceTradeData,
            };

            dispatch(updateOrganizationJobs({ id: organizationData._id, payload: updatedOrganizationData, ...data }));
        } else {
            notification.error({
                message: "Error",
                description: "Job not found in the source category.",
            });
        }
    };

    return (
        <>
            <Spin spinning={loading} fullscreen />
            <div style={{ padding: '24px' }}>
                <Card
                    title="Move Jobs"
                    bordered={false}
                    style={{
                        width: '100%',
                        marginBottom: 24,
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: '10px',
                    }}
                    headStyle={{ textAlign: 'center', color: '#555', fontWeight: 'bold' }}
                >
                    <Row gutter={16} justify="center">
                        <Col xs={24} lg={10}>
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <Select
                                    placeholder="Select Trade"
                                    onChange={handleTradeChange}
                                    style={{ width: '100%' }}
                                    value={selectedTrade}
                                >
                                    {trades.map((trade) => (
                                        <Option key={trade.id} value={trade.id}>{trade.name}</Option>
                                    ))}
                                </Select>

                                <Select
                                    placeholder="Select Service Type"
                                    onChange={handleServiceTypeChange}
                                    style={{ width: '100%' }}
                                    disabled={!selectedTrade}
                                    value={selectedServiceType}
                                >
                                    {services.map((type) => (
                                        <Option key={type.id} value={type.id}>{type.name.charAt(0).toUpperCase() + type.name.slice(1)}</Option>
                                    ))}
                                </Select>

                                <Select
                                    placeholder="Select Category"
                                    onChange={handleCategoryChange}
                                    style={{ width: '100%' }}
                                    disabled={!selectedServiceType}
                                    value={selectedCategory}
                                >
                                    {categories.map((category) => (
                                        <Option key={category._id} value={category._id}>{category.category_name}</Option>
                                    ))}
                                </Select>

                                <Select
                                    placeholder="Select Job"
                                    onChange={handleJobChange}
                                    style={{ width: '100%' }}
                                    disabled={!selectedCategory}
                                    value={selectedJob}
                                >
                                    {jobs.map((job) => (
                                        <Option key={job} value={job}>{job}</Option>
                                    ))}
                                </Select>
                            </Space>
                        </Col>
                        <Col xs={24} lg={2}>
                            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                <Title level={4}>To</Title>
                            </div>
                        </Col>
                        <Col xs={24} lg={10}>
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <Select
                                    placeholder="Select Target Service Type"
                                    onChange={handleTargetServiceTypeChange}
                                    style={{ width: '100%' }}
                                    disabled={!selectedTrade}
                                    value={targetServiceType}
                                >
                                    {services.map((type) => (
                                        <Option key={type.id} value={type.id}>{type.name.charAt(0).toUpperCase() + type.name.slice(1)}</Option>
                                    ))}
                                </Select>

                                <Select
                                    placeholder="Select Target Category"
                                    onChange={handleTargetCategoryChange}
                                    style={{ width: '100%' }}
                                    disabled={!targetServiceType}
                                    value={targetCategory}
                                >
                                    {targetCategories.map((category) => (
                                        <Option key={category._id} value={category._id}>{category.category_name}</Option>
                                    ))}
                                </Select>

                                <Button
                                    onClick={moveJob}
                                    style={{ width: '100%',marginBottom:"0.75rem", boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                                    disabled={!selectedJob || !targetCategory}
                                >
                                    Move Job
                                </Button>
                            </Space>
                            <Button onClick={handleReset} style={{ width: "100%" }}>
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}
