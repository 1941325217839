import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import { OrganizationTabs } from "../../utils/constants";
import "./index.css";

const Organization = () => {
	const { organizationData } = useSelector((state) => state?.organization);
	return (
		<Tabs
			items={OrganizationTabs.map((item) => {
				if (organizationData?.isSTCapacitySet && item?.isCapacityDependent)
					return null;
				return {
					key: item.name,
					label: (
						<span>
							{item.icon} {item.name}
						</span>
					),
					children: item.component,
				};
			})}
		/>
	);
};

export default Organization;
