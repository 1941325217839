import { useEffect, useState } from "react";
import {
	Button,
	Divider,
	Flex,
	Input,
	List,
	Select,
	Typography,
	Form,
	Spin,
} from "antd";
import { UserAddOutlined, UserOutlined, DeleteFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
	deleteUser,
	fetchOrganzationUsers,
	inviteUser,
} from "../../redux/adminSlice";

const { Title } = Typography;

const OrganizationUsers = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { organizationsList, currentOrganizationUsers, loading } = useSelector(
		(state) => state.admin
	);

	const [organization, setOrganization] = useState("");

	const addUserAccount = (value) => {
		const { email } = value;
		if (email) {
			dispatch(inviteUser({ email, organization }));
			form.setFieldValue("email", "");
		}
	};

	const removeUserAccount = (value) => {
		const { email } = value;
		if (email) {
			dispatch(deleteUser({ email, organization }));
		}
	};

	// Filter `option.label` match the user type `input`
	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	useEffect(() => {
		if (organization) {
			dispatch(fetchOrganzationUsers(organization));
			
		}
	}, [dispatch, organization]);

	

	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<UserAddOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Organization Users
				</Title>
			</Flex>

			<Flex gap={15}>
				<Select
					showSearch
					placeholder="Select an organization"
					optionFilterProp="children"
					onSelect={(e) => setOrganization(e)}
					filterOption={filterOption}
					options={organizationsList.map((item) => ({
						label: item.organization_name,
						value: item._id,
					}))}
					size="large"
					style={{ width: 400 }}
				/>
			</Flex>

			<Divider />

			{organization ? (
				<>
					<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
						<UserAddOutlined style={{ fontSize: 15 }} />
						<Title level={4} style={{ margin: 0 }}>
							Add User Account
						</Title>
					</Flex>

					<Form
						labelCol={{
							span: 6,
						}}
						wrapperCol={{
							span: 20,
						}}
						layout="vertical"
						style={{
							maxWidth: 600,
						}}
						onFinish={addUserAccount}
						onFinishFailed={onFinishFailed}
						form={form}
						autoComplete="new-state"
					>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message: "The input is not valid E-mail!",
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" size="large">
								Submit
							</Button>
						</Form.Item>
					</Form>

					<Divider />

					<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
						<UserOutlined style={{ fontSize: 15 }} />
						<Title level={4} style={{ margin: 0 }}>
							User Accounts
						</Title>
					</Flex>

					<List
						size="large"
						bordered
						dataSource={currentOrganizationUsers}
						renderItem={(item) => (
							<List.Item key={item._id}>
								<List.Item.Meta
									title={item.email}
									// description={item.organization}
								/>
								<DeleteFilled
									style={{ color: "#ff4d4f" }}
									height="1em"
									onClick={() => {
										removeUserAccount(item);
									}}
								/>
							</List.Item>
						)}
					/>
				</>
			) : null}
		</>
	);
};

export default OrganizationUsers;
