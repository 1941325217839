import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "./apiAxios.js";

export const fetchOrganzation = createAsyncThunk(
	"organization/fetch",
	async (id, { rejectWithValue }) => {
		const params = {
			id,
		};
		try {
			const { data } = await axios.get(`/org/fetch`, { params });
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchSTBusinessUnits = createAsyncThunk(
	"organization/scheduler/fetch",
	async (id, { rejectWithValue }) => {	
		const payload = {
			organizationId: id,
		};
		try {
			const { data } = await axios.post(`/scheduler/org/Details`, payload);
			return data;
		} catch (err) {
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchSTCampaigns = createAsyncThunk(
	"organization/scheduler/campaigns/fetch",
	async (id, { rejectWithValue }) => {
		const payload = {
			organizationId: id,
		};
		try {
			const { data } = await axios.post(`/scheduler/get/campaigns`, payload);
			return data;
		} catch (err) {
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchSTTechnicians = createAsyncThunk(
	"organization/scheduler/technicians/fetch",
	async (id, { rejectWithValue }) => {
		const payload = {
			organizationId: id,
		};
		try {
			const { data } = await axios.post(`/scheduler/get/technicians`, payload);
			return data;
		} catch (err) {
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchSTJobTags = createAsyncThunk(
	"organization/job/tags/fetch",
	async (id, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/scheduler/get/jobTags/${id}`);
			return data;
		} catch (err) {
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const updateOrganization = createAsyncThunk(
	"organization/update",
	async ({ id, payload }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/org/update`, {
				organizationId: id,
				payload,
			});
			notification.success({
				message: "Success",
				description: "Organization data succesfully updated.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const updateOrganizationJobs = createAsyncThunk(
	"organization/jobs/update",
	async ({ id, payload,...jobsInfo }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/org/jobs/update`, {
				organizationId: id,
				payload,
				jobsInfo
			});
			notification.success({
				message: "Success",
				description: "Organization data succesfully updated.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const updateOrganizationDomains = createAsyncThunk(
	"organization/domain",
	async ({ id, domain, }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/org/domains/update`, {
				organizationId: id,
				domain
			});
			notification.success({
				message: "Success",
				description: "Organization domain added succesfully.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const organization_custom_booking_url	= createAsyncThunk(
	"organization/custom_booking_url",
	async ({ id, payload }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/org/register/customurl`, {
				id,
				...payload
			});
			notification.success({
				message: "Success",
				description: "Custom Url stored successfully",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);


export const organizationSlice = createSlice({
	name: "organization",
	initialState: {
		loading: false,
		serviveTitanLoading: false,
		organizationData: null,
		serviceTitanBusinessUnits: [],
		serviceTitanJobs: [],
		serviceTitanJobTags: [],
		serviceTitanCampaigns: [],
		serviceTitanTechnicians: [],

		error: null,
	},
	reducers: {
		updateJobMappings: (state, { payload }) => {
			const { selectedTrade, services, serviceType, jobItemsData } = payload;
			let data = state.organizationData.jobs_mapping;
			const index = data?.findIndex((e) => e.trade === selectedTrade);
			if (index === -1) {
				const jobObject = {
					trade: selectedTrade,
				};
				services.forEach((item) => {
					Object.assign(jobObject, { [item.id]: [] });
				});
				data.push(jobObject);

				data[data.length - 1][serviceType] = jobItemsData;
			} else {
				data[index][serviceType] = jobItemsData;
			}
			state.organizationData.jobs_mapping = data;
		},
		updateTradeOfferings: (state, { payload }) => {
			const { trade, value } = payload;
			let data = state.organizationData.navigation_mapping;

			const findIndex = data.findIndex((e) => e?.trade?.name === trade);
			if (findIndex !== -1) {
				data[findIndex].isActive = value;
				Object.keys(data[findIndex].offeredServiceTypes)?.forEach((i) => {
					data[findIndex].offeredServiceTypes[i] = value;
				});
			}

			state.organizationData.navigation_mapping = data;
		},
		updateServiceTypeOfferings: (state, { payload }) => {
			const { trade, serviceType, value } = payload;
			let data = state.organizationData.navigation_mapping;

			const findIndex = data.findIndex((e) => e?.trade?.name === trade);
			if (findIndex !== -1) {
				data[findIndex].offeredServiceTypes[serviceType] = value;
			}
			state.organizationData.navigation_mapping = data;
		},
	},
	extraReducers: {
		//create Trade
		[fetchOrganzation.pending]: (state) => {
			state.loading = true;
		},
		[fetchOrganzation.fulfilled]: (state, { payload }) => {
			state.organizationData = payload;
			state.loading = false;
		},
		[fetchOrganzation.rejected]: (state, { payload }) => {
			state.organizationData = null;
			state.error = payload;
			state.loading = false;
		},

		[fetchSTBusinessUnits.pending]: (state) => {
			state.loading = true;
			state.serviveTitanLoading = true;
		},
		[fetchSTBusinessUnits.fulfilled]: (state, { payload }) => {
			state.serviceTitanBusinessUnits = payload.businessUnits;
			state.serviceTitanJobs = payload.jobs;
			state.loading = false;
			state.serviveTitanLoading = false;
		},
		[fetchSTBusinessUnits.rejected]: (state, { payload }) => {
			state.serviceTitanBusinessUnits = [];
			state.serviceTitanJobs = [];
			state.error = payload;
			state.loading = false;
			state.serviveTitanLoading = false;
		},

		[fetchSTCampaigns.pending]: (state) => {
			state.loading = true;
			state.serviveTitanLoading = true;
		},
		[fetchSTCampaigns.fulfilled]: (state, { payload }) => {
			state.serviceTitanCampaigns = payload;
			state.loading = false;
			state.serviveTitanLoading = false;
		},
		[fetchSTCampaigns.rejected]: (state, { payload }) => {
			state.serviceTitanCampaigns = [];
			state.error = payload;
			state.loading = false;
			state.serviveTitanLoading = false;
		},

		[fetchSTJobTags.pending]: (state) => {
			state.loading = true;
			state.serviveTitanLoading = true;
		},
		[fetchSTJobTags.fulfilled]: (state, { payload }) => {
			state.serviceTitanJobTags = payload;
			state.loading = false;
			state.serviveTitanLoading = false;
		},
		[fetchSTJobTags.rejected]: (state, { payload }) => {
			state.serviceTitanJobTags = [];
			state.error = payload;
			state.loading = false;
			state.serviveTitanLoading = false;
		},

		[fetchSTTechnicians.pending]: (state) => {
			state.loading = true;
			state.serviveTitanLoading = true;
		},
		[fetchSTTechnicians.fulfilled]: (state, { payload }) => {
			state.serviceTitanTechnicians = [{ id: "", name: "" }, ...payload];
			state.loading = false;
			state.serviveTitanLoading = false;
		},
		[fetchSTTechnicians.rejected]: (state, { payload }) => {
			state.serviceTitanTechnicians = [];
			state.error = payload;
			state.loading = false;
			state.serviveTitanLoading = false;
		},

		[updateOrganization.pending]: (state) => {
			state.loading = true;
		},
		[updateOrganization.fulfilled]: (state, { payload }) => {
			state.organizationData = payload;
			state.loading = false;
		},
		[updateOrganization.rejected]: (state, { payload }) => {
			state.error = payload?.updatedOrganization;
			state.loading = false;
		},
		[updateOrganizationJobs.pending]: (state) => {
			state.loading = true;
		},
		[updateOrganizationJobs.fulfilled]: (state, { payload }) => {
			state.organizationData = payload?.updatedOrganization
			;
			state.loading = false;
		},
		[updateOrganizationJobs.rejected]: (state, { payload }) => {
			state.error = payload?.updatedOrganization;
			state.loading = false;
		},
		[updateOrganizationDomains.pending]: (state) => {
			state.loading = true;
		},
		[updateOrganizationDomains.fulfilled]: (state, { payload }) => {
			state.organizationData = payload?.updatedOrganization
			;
			state.loading = false;
		},
		[updateOrganizationDomains.rejected]: (state, { payload }) => {
			state.error =  payload?.updatedOrganization;
			state.loading = false;
		},
	},
});

// Action creators are generated for each case reducer function

export const {
	updateJobMappings,
	updateTradeOfferings,
	updateServiceTypeOfferings,
} = organizationSlice.actions;

export default organizationSlice.reducer;
