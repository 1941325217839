import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Flex, Row, Spin, Tabs, Typography } from "antd";
import { CloudServerOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { initiateInitialExtraction } from "../../redux/adminSlice";
import { fetchSTBusinessUnits } from "../../redux/organizationSlice";
import ServiceTitanDetails from "./ServiceTitanBusinessUnits/ServiceTitanDetails";
import JobsMappingUpload from "./ServiceTitanBusinessUnits/UploadJobMappings";
const { Title, Text } = Typography;

const childComponents = [
	{
		label: `Service Titan Details`,
		key: 1,
		children: <ServiceTitanDetails />,
	},
	{
		label: `Upload Job Mappings`,
		key: 2,
		children: <JobsMappingUpload />,
	},
];

const ServiceTitanBusinessUnits = () => {
	// const dispatch = useDispatch();
	// const {
	// 	organizationData,
	// 	serviceTitanBusinessUnits,
	// 	serviceTitanJobs,
	// 	loading,
	// 	serviveTitanLoading,
	// } = useSelector((state) => state?.organization);
	// const { isAdmin, currentOrganizationId } = useSelector(
	// 	(state) => state?.auth
	// );

	return (
		<Tabs
			defaultActiveKey="1"
			tabPosition={"left"}
			style={{
				height: "auto",
			}}
			items={childComponents.map((item, i) => {
				return {
					label: item.label,
					key: item.key,
					children: item.children,
				};
			})}
		/>
	);
};

export default ServiceTitanBusinessUnits;
