import { Flex, Switch, Typography } from "antd";
import { SingleSelect } from "../General/Select";
import { useSelector } from "react-redux";
const { Text } = Typography;

const JobMapping = ({
	jobId,
	mappedData,
	// serviceTitanBusinessUnits,
	// serviceTitanJobs,
	filterOption,
	onChange,
}) => {
	const { serviceTitanBusinessUnits, serviceTitanJobs } = useSelector(
		(state) => state?.organization
	);

	// const getSTJobItems = (BUId = "") => {
	// 	const index = serviceTitanBusinessUnits?.findIndex(
	// 		(e) => e.businessUnitId === BUId
	// 	);
	// 	if (index !== -1) {
	// 		return serviceTitanBusinessUnits[index]?.jobs;
	// 	}
	// 	return [];
	// };

	// const JobItems = getSTJobItems(mappedData?.st_business_unit_id);

	return (
		<Flex style={{ width: "100%" }} gap={20}>
			<SingleSelect
				title={"Select Business Unit"}
				showSearch
				placeholder="Select Business Unit"
				optionFilterProp="children"
				value={mappedData?.st_business_unit_id}
				onChange={(e) => {
					const selectedST = serviceTitanBusinessUnits.find(
						(val) => val.id === e
					);
					onChange(
						{
							st_business_unit_id: e,
							st_business_unit_name: selectedST.name || "",
						},
						jobId
					);
				}}
				filterOption={filterOption}
				options={serviceTitanBusinessUnits?.map((item) => ({
					label: item.name,
					value: item.id,
				}))}
				size="middle"
				style={{ width: 300, marginBottom: "0px" }}
				dropdownStyle={{ width: 350 }}
			/>

			<Flex vertical style={{ minWidth: "max-content" }} gap={10}>
				<Text type="secondary" strong underline>
					Is Member
				</Text>
				<Switch
					checked={mappedData?.is_member}
					style={{ width: 35 }}
					onChange={(e) => onChange({ is_member: e }, jobId)}
				/>
			</Flex>

			<SingleSelect
				title={"Non Member Job Type"}
				showSearch
				placeholder="Non Member Job Type"
				optionFilterProp="children"
				value={mappedData?.st_non_member_job_id}
				onChange={(e) => {
					const selectedJobItem = serviceTitanJobs.find((val) => val.id === e);
					onChange(
						{
							st_non_member_job_id: e,
							st_non_member_job_name: selectedJobItem.name || "",
						},
						jobId
					);
				}}
				filterOption={filterOption}
				options={serviceTitanJobs?.map((item) => ({
					label: item.name,
					value: item.id,
				}))}
				size="middle"
				style={{ width: 300 }}
				dropdownStyle={{ width: 350 }}
			/>

			{mappedData?.is_member ? (
				<SingleSelect
					title={"Member Job Type"}
					showSearch
					placeholder="Member Job Type"
					optionFilterProp="children"
					value={mappedData?.st_member_job_id}
					onChange={(e) => {
						const selectedJobItem = serviceTitanJobs.find(
							(val) => val.id === e
						);
						onChange(
							{
								st_member_job_id: e,
								st_member_job_name: selectedJobItem.name || "",
							},
							jobId
						);
					}}
					filterOption={filterOption}
					options={serviceTitanJobs?.map((item) => ({
						label: item.name,
						value: item.id,
					}))}
					size="middle"
					style={{ width: 300 }}
					dropdownStyle={{ width: 350 }}
				/>
			) : null}
		</Flex>
	);
};

export default JobMapping;
