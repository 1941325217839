import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "./apiAxios.js";

export const createJob = createAsyncThunk(
	"job/create",
	async ({ values }, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/admin/job/create`, values);
			notification.success({
				message: "Success",
				description: "Job item succesfully created.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchJobs = createAsyncThunk(
	"jobs/fetch",
	async (_, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/admin/jobs/fetch`);
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const jobsSlice = createSlice({
	name: "jobs",
	initialState: {
		loading: false,
		jobs: [],
		error: null,
	},
	extraReducers: {
		//create Trade
		[createJob.pending]: (state) => {
			state.loading = true;
		},
		[createJob.fulfilled]: (state, { payload }) => {
			state.jobs = [...state.jobs, payload];
			state.loading = false;
		},
		[createJob.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Fetch Trades
		[fetchJobs.pending]: (state) => {
			state.loading = true;
		},
		[fetchJobs.fulfilled]: (state, { payload }) => {
			state.jobs = payload;
			state.loading = false;
		},
		[fetchJobs.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},
	},
});

// Action creators are generated for each case reducer function

export const { authenticate } = jobsSlice.actions;

export default jobsSlice.reducer;
