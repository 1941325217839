import React from "react";
import { Button, Col, Flex, Form, Image, Input, notification, Row } from "antd";
import { useDispatch } from "react-redux";
import { forgotUserPassword, login } from "../../redux/authSlice";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const onFinish = (values) => {
		dispatch(forgotUserPassword({ email: values.email}))
	};


	return (
		<Row style={{ height: "100%" }}>
			<Col span={12} style={{ background: "#fed123", height: "100%" }}>
				<Flex justify="center" align="center" style={{ height: "100%" }}>
					<Image
						src="https://autobot.ai/img/autologo.svg"
						height={100}
						preview={false}
					/>
				</Flex>
			</Col>

			<Col
				span={12}
				style={{
					backgroundImage: `url(https://autobot.ai/img/Whats-the-buzz-bg.png)`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundColor: "#fafcff",
				}}
			>
				<Flex
					justify="center"
					align="center"
					vertical={true}
					style={{ height: "100%" }}
					gap={30}
				>
					<Image
						src="https://autobot.ai/img/Logo_bot_icon_new.svg"
						height={80}
						preview={false}
					/>
					<Form
						name="basic"
						style={{
							width: 300,
						}}
						form={form}
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						autoComplete="on"
						layout="vertical"
					>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message: "Please input a valid Email!",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Flex>
			</Col>
		</Row>
	);
};
export default ForgotPassword;
