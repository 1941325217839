import {
	Button,
	Divider,
	Flex,
	Input,
	List,
	Popconfirm,
	Spin,
	Typography,
} from "antd";
import {
	AlignLeftOutlined,
	SettingOutlined,
	DeleteFilled,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { createOrganization, deleteOrganization } from "../../redux/adminSlice";

const { Title } = Typography;
const AdminOrganizations = () => {
	const dispatch = useDispatch();
	const { organizationsList, loading } = useSelector((state) => state.admin);
	const [organizationName, setOrganizationName] = useState("");
	
	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<SettingOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Create New Organization
				</Title>
			</Flex>

			<Flex vertical gap={20}>
				<Input
					style={{
						maxWidth: 600,
					}}
					showCount
					maxLength={50}
					placeholder="Organization Name"
					size="large"
					onChange={(e) => setOrganizationName(e.target.value)}
				/>

				<Button
					type="primary"
					size="large"
					disabled={organizationName === ""}
					onClick={() =>
						dispatch(
							createOrganization({ organization_name: organizationName })
						)
					}
					style={{ width: 180 }}
				>
					Create Organization
				</Button>
			</Flex>

			<Divider />

			<Flex vertical gap={20} style={{ marginBottom: "50px" }}>
				<Flex>
					<AlignLeftOutlined style={{ fontSize: 20 }} />
					<Title level={3} style={{ margin: "0px 20px" }}>
						Organizations List
					</Title>
				</Flex>
				<List
					size="large"
					bordered
					dataSource={organizationsList}
					renderItem={(item) => (
						<List.Item key={item?._id}>
							<List.Item.Meta title={item?.organization_name} />

							<Popconfirm
								title="Delete Organization?"
								description="Are you sure to delete this Organization?"
								placement="left"
								icon={
									<QuestionCircleOutlined
										style={{
											color: "red",
										}}
									/>
								}
								onConfirm={() => dispatch(deleteOrganization({ id: item._id }))}
								okText="Delete"
								okType="danger"
								cancelText="Cancel"
							>
								<DeleteFilled style={{ color: "red" }} />
							</Popconfirm>
						</List.Item>
					)}
				/>
			</Flex>
		</>
	);
};

export default AdminOrganizations;
