import React, { useState } from "react";
import { Button, Col, InputNumber, Row, Select, Table, TimePicker, Typography, notification } from "antd";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

const { Text } = Typography;
const { Option } = Select;
const format = "HH:mm";

const newWindow = {
  start: "00:00",
  end: "00:00",
  bookingSlots: 0,
};

const AddCapacityToBU = ({ businessUnits, capacityWindow, setCapacityWindow }) => {
  const [selectedDayId, setSelectedDayId] = useState(null);

  const handleAddWindow = (dayId) => {
    const updatedCapacity = { ...capacityWindow };
    const dayIndex = updatedCapacity.capacity.findIndex((item) => item.id === dayId);
    if (dayIndex !== -1) {
      updatedCapacity.capacity[dayIndex].windows.push({ id: uuidv4(), ...newWindow });
      setCapacityWindow(updatedCapacity);
    }
  };

  const handleChangeWindow = (dayId, windowId, field, value) => {
    const updatedCapacity = { ...capacityWindow };
    const dayIndex = updatedCapacity.capacity.findIndex((item) => item.id === dayId);
    const windowIndex = updatedCapacity.capacity[dayIndex].windows.findIndex((win) => win.id === windowId);

    if (field === "start" || field === "end") {
      if (!dayjs(value, format).isValid()) {
        notification.error({
          message: "Error",
          description: "Invalid time format. Please enter a valid time.",
          duration: 3,
        });
        return;
      }
    }

    updatedCapacity.capacity[dayIndex].windows[windowIndex][field] = value;
    setCapacityWindow(updatedCapacity);
  };

  const handleRemoveWindow = (dayId, windowId) => {
    const updatedCapacity = { ...capacityWindow };
    const dayIndex = updatedCapacity.capacity.findIndex((item) => item.id === dayId);
    updatedCapacity.capacity[dayIndex].windows = updatedCapacity.capacity[dayIndex].windows.filter((win) => win.id !== windowId);
    setCapacityWindow(updatedCapacity);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: "Day",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Time Slot",
      dataIndex: "windows",
      key: "windows",
      render: (windows, record) => (
        <Row gutter={[16, 16]}>
          {windows.map((win) => (
            <Col span={24} key={win.id}>
              <Row gutter={[16, 16]} align="middle">
                <Col flex="auto">
                  <TimePicker
                    format={format}
                    value={dayjs(win.start, format)}
                    onChange={(time, timeString) =>
                      handleChangeWindow(record.id, win.id, "start", timeString)
                    }
                    style={{ width: "100%" }}
                    showNow={false}
                  />
                </Col>
                <Col>
                  <Text strong>to</Text>
                </Col>
                <Col flex="auto">
                  <TimePicker
                    format={format}
                    value={dayjs(win.end, format)}
                    onChange={(time, timeString) =>
                      handleChangeWindow(record.id, win.id, "end", timeString)
                    }
                    style={{ width: "100%" }}
                    showNow={false}
                  />
                </Col>
                <Col>
                  <InputNumber
                    min={0}
                    max={999}
                    value={win.bookingSlots}
                    onChange={(value) =>
                      handleChangeWindow(record.id, win.id, "bookingSlots", value)
                    }
                    placeholder="Booking Slots"
                    style={{ width: 120 }}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#ff7875" }}
                    onClick={() => handleRemoveWindow(record.id, win.id)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      ),
    },
  ];

  return (
    <Col>
      <Row style={{ marginBottom: 20 }}>
        <Select
          mode="multiple"
          placeholder="Select Business Units"
          value={capacityWindow?.associatedBUs}
          onChange={(values) =>
            setCapacityWindow((prev) => ({ ...prev, associatedBUs: values }))
          }
          style={{ width: "100%" }}
          options={businessUnits.map((item) => ({
            value: item?.value,
            label: item?.label,
          }))}
          filterOption={filterOption}
          showSearch
          size="large"
        />
      </Row>
      {capacityWindow?.capacity?.length > 0 ? (
        <Table
         style={{ maxHeight: "300px", overflowY: "auto" }}
          columns={columns}
          dataSource={capacityWindow?.capacity}
          rowKey={(record) => record.id}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <div style={{ margin: "20px 0px", maxHeight: "300px", overflowY: "auto" }}>
                <Button
                  type="primary"
                  style={{ margin: "10px 0px" }}
                  onClick={() => handleAddWindow(record.id)}
                  disabled={record.windows.length >= 24}
                >
                  + Add Arrival Window
                </Button>

              </div>
            ),
            rowExpandable: (record) => record.windows.length < 24,
            expandedRowKeys: [selectedDayId],
            onExpand: (expanded, record) => setSelectedDayId(expanded ? record.id : null),
          }}
        />
      ) : (
        <Text>No capacity windows available. Please add new windows.</Text>
      )}
    </Col>
  );
};

export default AddCapacityToBU;
