import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { emailType } from "../utils/constants.js";
import axios from "./apiAxios.js";

export const createOrganization = createAsyncThunk(
	"organization/create",
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/org/register`, { payload });
			notification.success({
				message: "Success",
				description: "Organization created succesfully.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchAllOrganzations = createAsyncThunk(
	"organization/fetchAll",
	async (_, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/org/fetchAll`);
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const inviteUser = createAsyncThunk(
	"invite/user",
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/admin/invite/user`, { ...payload,type:emailType.Verification });
			notification.success({
				message: "Success",
				description: "Invite sent succesfully.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const deleteUser = createAsyncThunk(
	"remove/user",
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/admin/remove/user`, { ...payload });
			notification.success({
				message: "Success",
				description: "User Deleted Succesfully.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchOrganzationUsers = createAsyncThunk(
	"organization/fetch/users",
	async (id, { rejectWithValue }) => {
		const params = {
			id,
		};
		try {
			const { data } = await axios.get(`/org/fetch/users`, { params });
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const initiateInitialExtraction = createAsyncThunk(
	"organization/serviceTitan/data",
	async (id, { rejectWithValue }) => {
		const params = {
			organizationId: id,
		};
		try {
			const { data } = await axios.post(`/org/initiate/extraction`, {
				...params,
			});

			notification.success({
				message: "Success",
				description: "Initial Data Request sent succesfully.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const deleteOrganization = createAsyncThunk(
	"organization/delete",
	async ({ id }, { rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/org/delete`, { data: { id } });
			notification.success({
				message: "Success",
				description: "Organization deleted succesfully.",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const adminSlice = createSlice({
	name: "admin",
	initialState: {
		loading: false,
		organizationsList: [],
		currentOrganizationUsers: [],
		error: null,
	},
	reducers: {
		// authenticate: (state) => {
		// 	state.isAuthenticated = !state.isAuthenticated;
		// },
	},
	extraReducers: {
		//create Trade
		[createOrganization.pending]: (state) => {
			state.loading = true;
		},
		[createOrganization.fulfilled]: (state, { payload }) => {
			state.organizationsList = [...state.organizationsList, payload];
			state.loading = false;
		},
		[createOrganization.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Fetch All Organizations
		[fetchAllOrganzations.pending]: (state) => {
			state.loading = true;
		},
		[fetchAllOrganzations.fulfilled]: (state, { payload }) => {
			state.organizationsList = payload;
			state.loading = false;
		},
		[fetchAllOrganzations.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Delete Organization
		[deleteOrganization.pending]: (state) => {
			state.loading = true;
		},
		[deleteOrganization.fulfilled]: (state, { payload }) => {
			state.organizationsList = payload;
			state.loading = false;
		},
		[deleteOrganization.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Fetch Users
		[fetchOrganzationUsers.pending]: (state) => {
			state.loading = true;
		},
		[fetchOrganzationUsers.fulfilled]: (state, { payload }) => {
			state.currentOrganizationUsers = payload;
			state.loading = false;
		},
		[fetchOrganzationUsers.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Invite User
		[inviteUser.pending]: (state) => {
			state.loading = true;
		},
		[inviteUser.fulfilled]: (state, { payload }) => {
			state.currentOrganizationUsers = [
				...state.currentOrganizationUsers,
				payload,
			];
			state.loading = false;
		},
		[inviteUser.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Delete User
		[deleteUser.pending]: (state) => {
			state.loading = true;
		},
		[deleteUser.fulfilled]: (state, { payload }) => {
			state.currentOrganizationUsers = state.currentOrganizationUsers?.filter(
				(e) => e?.email !== payload?.email
			);
			state.loading = false;
		},
		[deleteUser.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Initial Extraction
		[initiateInitialExtraction.pending]: (state) => {
			state.loading = true;
		},
		[initiateInitialExtraction.fulfilled]: (state) => {
			state.loading = false;
		},
		[initiateInitialExtraction.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},
	},
});

// Action creators are generated for each case reducer function

export const { authenticate } = adminSlice.actions;

export default adminSlice.reducer;
