import { useEffect, useState } from "react";
import { Select, Typography, Row, Col, Spin, Collapse, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { InsertRowLeftOutlined} from "@ant-design/icons";
import { updateOrganization } from "../../redux/organizationSlice";

const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const NotificationAccounts = () => {
  const dispatch = useDispatch();
  const { organizationData, loading } = useSelector(
    (state) => state.organization
  );

  console.log(organizationData)

  const [jobBookingNotifications, setJobBookingNotifications] = useState(
    []
  );
  const [leadNotifications, setLeadNotifications] = useState(
    []
  );

  useEffect(()=>{
	setJobBookingNotifications(organizationData?.notificationAccounts || [])
	setLeadNotifications(organizationData?.leadNotifications || [])
  },[organizationData])


  const handleJobBookingChange = (value) => {
    setJobBookingNotifications(value);
	console.log(organizationData)
	console.log(value)
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: {
			notificationAccounts: value,
        },
      })
    );
  };

  const handleLeadNotificationsChange = (value) => {
    setLeadNotifications(value);
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: {
          leadNotifications: value,
        },
      })
    );
  };

  return (
    <>
    <Spin spinning={loading} fullscreen />
	<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
		<InsertRowLeftOutlined style={{ fontSize: 20 }} />
		<Title level={3} style={{ margin: 0 }}>
					Notification Accounts
		</Title>
	</Flex>
      <Row>
        <Col span={24}>
          <Collapse  defaultActiveKey={['1','2']} style={{ marginBottom: '20px' }}>
            <Panel style={{fontWeight:500}} header="Job Booking Notifications" key="1">
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Add job booking notifications"
                  value={jobBookingNotifications}
                  onChange={handleJobBookingChange}
                  tokenSeparators={[',']}
                  open={false}
				  suffixIcon={null}
                >
                  {jobBookingNotifications.map((notification) => (
                    <Option key={notification} value={notification}>
                      {notification}
                    </Option>
                  ))}
                </Select>
             
            </Panel>
            
            <Panel  style={{fontWeight:500}} header="Lead Notifications" key="2">
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Add lead notifications"
                  value={leadNotifications}
                  onChange={handleLeadNotificationsChange}
                  tokenSeparators={[',']}
                  open={false}
				  suffixIcon={null}
                >
                  {leadNotifications.map((notification) => (
                    <Option key={notification} value={notification}>
                      {notification}
                    </Option>
                  ))}
                </Select>
           
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default NotificationAccounts;
